import React, { Dispatch, SetStateAction } from 'react';
import axios from '../../../modules/utils/axios.utils';
import { MACHINE_MANAGEMENT_RETRY_INTERVAL, MACHINE_MANAGEMENT_TOTAL_RETRIES } from '../../../modules/utils/env';
import { SnackBarContext } from '../../../modules/snackBar/contexts/SnackBarContext';

interface LiquidSourcesAutoTreatContextInterface {
  fetchCachedLiquidSourcesAutoTreat?: (machineSerial: string) => Promise<void>;
  fetchLiquidSourcesAutoTreat?: (machineSerial: string, onSuccess?: () => void) => Promise<void>;
  liquidSourcesRefreshedAt?: string;
  liquidSources?: any[];
  liquidSourcesLoading?: boolean;

  detailPage?: boolean;
  setDetailPage?: Dispatch<SetStateAction<boolean>>;

  setActiveMachine?: Dispatch<SetStateAction<Record<string, string>>>;
  activeMachine?: Record<string, string>;

  setActiveLiquidSourceAutoTreat?: Dispatch<SetStateAction<Record<string, string>>>;
  activeLiquidSourceAutoTreat?: Record<string, string>;

  handleGenericFilterChange?: (genericFilter: string) => Promise<void>;
  genericFilter?: string;
}

const LiquidSourcesAutoTreatContext = React.createContext<LiquidSourcesAutoTreatContextInterface>({});

const LiquidSourcesAutoTreatContextConsumer = LiquidSourcesAutoTreatContext.Consumer;
const LiquidSourcesAutoTreatContextProvider: React.FC = ({ children }) => {
  const { showErrorSnackBar } = React.useContext(SnackBarContext);

  const [liquidSources, setLiquidSourcesAutoTreat] = React.useState([]);
  const [liquidSourcesLoading, setLiquidSourcesAutoTreatLoading] = React.useState(true);
  const [liquidSourcesRefreshedAt, setLiquidSourcesAutoTreatRefreshedAt] = React.useState<string>();
  const [activeLiquidSourceAutoTreat, setActiveLiquidSourceAutoTreat] = React.useState({});
  const [detailPage, setDetailPage] = React.useState<boolean>(false);
  const [activeMachine, setActiveMachine] = React.useState<Record<string, string>>({
    label: localStorage.getItem('machineManagementAutoTreatMachine') || 'Select A Machine',
    value: localStorage.getItem('machineManagementAutoTreatMachine') || 'Select A Machine',
  });
  const [genericFilter, setGenericFilter] = React.useState<string>('');

  React.useEffect(() => {
    if (activeMachine?.value === 'Select A Machine') setLiquidSourcesAutoTreatLoading(false);
  }, [activeMachine]);

  const fetchCachedLiquidSourcesAutoTreat = async (machineSerial: string) => {
    setLiquidSourcesAutoTreatLoading(true);
    setLiquidSourcesAutoTreatRefreshedAt(null);
    const retryCount = 0;

    axios
      .get<string, any>(`api/autotreat/onprem/liquid_sources/${machineSerial}/`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      })
      .then((response) => {
        setLiquidSourcesAutoTreatLoading(false);
        setLiquidSourcesAutoTreat(response.data.results);
        setLiquidSourcesAutoTreatRefreshedAt(response.data.created_at);
      })
      .catch((error) => {
        setLiquidSourcesAutoTreatLoading(false);
        setLiquidSourcesAutoTreat([]);
        setLiquidSourcesAutoTreatRefreshedAt(null);
      });
  };

  const fetchLiquidSourcesAutoTreat = async (machineSerial: string, onSuccess?: () => void) => {
    setLiquidSourcesAutoTreatLoading(true);
    setLiquidSourcesAutoTreatRefreshedAt(null);
    let retryCount = 0;

    axios
      .get<string, any>(
        `api/autotreat/onprem/liquid_sources/?serial-number=${machineSerial}&generic-filter=${genericFilter}`,
        {
          headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
        },
      )

      .then((res) => {
        const fetchTimer = window.setInterval(() => {
          axios
            .get<string, any>(`api/autotreat/onprem/liquid_sources/${res.data.result}`, {
              headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
            })
            .then((response) => {
              window.clearTimeout(fetchTimer);
              setLiquidSourcesAutoTreatLoading(false);
              setLiquidSourcesAutoTreat(response.data.results);
              setLiquidSourcesAutoTreatRefreshedAt(response.data.created_at);
            })
            .catch((error) => {
              retryCount += 1;

              if (retryCount > MACHINE_MANAGEMENT_TOTAL_RETRIES) {
                window.clearInterval(fetchTimer);
                showErrorSnackBar('Unable to load liquid sources');
                setLiquidSourcesAutoTreatLoading(false);
                setLiquidSourcesAutoTreat([]);
                setLiquidSourcesAutoTreatRefreshedAt(null);
              }
            });
        }, MACHINE_MANAGEMENT_RETRY_INTERVAL);
      });
  };

  const handleGenericFilterChange = async (genericFilter: string) => {
    setGenericFilter(genericFilter);
  };

  return (
    <LiquidSourcesAutoTreatContext.Provider
      value={{
        fetchCachedLiquidSourcesAutoTreat,
        fetchLiquidSourcesAutoTreat,
        liquidSources,
        liquidSourcesRefreshedAt,
        liquidSourcesLoading,

        detailPage,
        setDetailPage,

        setActiveLiquidSourceAutoTreat,
        activeLiquidSourceAutoTreat,

        setActiveMachine,
        activeMachine,

        handleGenericFilterChange,
        genericFilter,
      }}
    >
      {children}
    </LiquidSourcesAutoTreatContext.Provider>
  );
};

export { LiquidSourcesAutoTreatContextProvider, LiquidSourcesAutoTreatContextConsumer, LiquidSourcesAutoTreatContext };
