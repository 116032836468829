import { CustomersMajor } from '@shopify/polaris-icons';

export const superAdminRoutes = {
  label: 'Admin',
  url: '#',
  excludePaths: ['#'],
  icon: CustomersMajor,
  matches: true,
  subNavigationItems: [
    {
      url: '/account_management/alerts',
      disabled: false,
      label: 'Alerting',
    },
    {
      url: '/admin/machine_management',
      disabled: false,
      label: 'Machine Management',
    },
    {
      url: '/admin/user_management',
      disabled: false,
      label: 'User Management',
    },
    {
      url: '/admin/company_management',
      disabled: false,
      label: 'Company Management',
    },
    {
      url: '/admin/machine_certificates',
      disabled: false,
      label: 'MQTT Certificate Management',
    },
    {
      url: '/admin/api_management',
      disabled: false,
      label: 'API Management',
    },
    {
      url: '/admin/permission_management',
      disabled: false,
      label: 'Permissions Management',
    },
    {
      url: '/admin/machine_execute_sql',
      disabled: false,
      label: 'Execute Machine SQL',
    },
    {
      url: '/admin/internal_analytics_autotreat',
      disabled: false,
      label: 'AutoTreat Dashboard',
    },
  ],
};

export const adminRoutes = {
  label: 'Admin',
  url: '/admin',
  icon: CustomersMajor,
  matches: true,
  subNavigationItems: [
    {
      url: '/admin/machine_management',
      disabled: false,
      label: 'Machine Management',
    },
    {
      url: '/admin/user_management',
      disabled: false,
      label: 'User Management',
    },
    {
      url: '/admin/company_management',
      disabled: false,
      label: 'Company Management',
    },
    {
      url: '/admin/csv_import',
      disabled: false,
      label: 'CSV Import',
    },
  ],
};

export const supportAdminRoutes = {
  label: 'Admin',
  url: '/admin',
  icon: CustomersMajor,
  matches: true,
  subNavigationItems: [
    {
      url: '/admin/machine_management',
      disabled: false,
      label: 'Machine Management',
    },
    {
      url: '/admin/user_management',
      disabled: false,
      label: 'User Management',
    },
  ],
};
