import React from 'react';
import axios from '../../utils/axios.utils';
import { MachineRequest } from '../model';

interface MachineRequestsContextInterface {
  fetchMachineRequests?: (filterParams?: string, pageSize?: any, per_page?: any) => Promise<void>;
  machineRequests?: MachineRequest[];
  machineRequestsLoading?: boolean;
  totalMachineRequestsCount?: number;

  fetchMachineRequest?: (filterParams?: string, pageSize?: any) => Promise<void>;
  machineRequest?: MachineRequest;
  machineRequestLoading?: boolean;

  fetchMachineRequestRecords?: (
    machineRequestId?: string,
    fitlerParams?: any,
    pageSize?: any,
    per_page?: any,
    onSuccess?: () => void,
  ) => Promise<void>;
  machineRequestRecords?: any;
  machineRequestRecordsLoading?: boolean;
  totalMachineRequestRecordsCount?: number;
  hasPendingRecords?: boolean;

  fetchMachineRequestRecord?: (machineRequestId?: string, machine_request_record_id?: string) => Promise<void>;
  machineRequestRecord?: any;
  machineRequestRecordLoading?: boolean;

  errorMessage?: string;
}

const MachineRequestsContext = React.createContext<MachineRequestsContextInterface>({});

const MachineRequestsContextConsumer = MachineRequestsContext.Consumer;
const MachineRequestsContextProvider: React.FC = ({ children }) => {
  const [machineRequests, setMachineRequests] = React.useState<MachineRequest[]>([]);
  const [machineRequestsLoading, setMachineRequestsLoading] = React.useState<boolean>(true);
  const [totalMachineRequestsCount, setTotalMachineRequestsCount] = React.useState<number>(0);
  const [hasPendingRecords, setHasPendingRecords] = React.useState<boolean>(false);

  const [machineRequest, setMachineRequest] = React.useState<MachineRequest | null>(null);
  const [machineRequestLoading, setMachineRequestLoading] = React.useState<boolean>(true);

  const [machineRequestRecords, setMachineRequestRecords] = React.useState<MachineRequest>();
  const [machineRequestRecordsLoading, setMachineRequestRecordsLoading] = React.useState<boolean>(false);
  const [totalMachineRequestRecordsCount, setTotalMachineRequestRecordsCount] = React.useState<number>(0);

  const [machineRequestRecord, setMachineRequestRecord] = React.useState<MachineRequest>();
  const [machineRequestRecordLoading, setMachineRequestRecordLoading] = React.useState<boolean>(false);

  const [errorMessage, setErrorMessage] = React.useState<string>('');

  const computeRecordInfo = (record) => {
    const jsonString = record.request_record_object.replace(/'/g, '"');
    const parsedObject = JSON.parse(jsonString);

    const recordName =
      parsedObject.customer_name || parsedObject.operation || parsedObject.product_name || parsedObject.variety || '';

    let recordInfo = recordName;
    if (parsedObject.api_partner) {
      recordInfo += ` | ${parsedObject.api_partner}`;
    }

    ['partner_record_id_1', 'partner_record_id_2', 'partner_record_id_3'].forEach((key) => {
      if (parsedObject[key]) {
        recordInfo += ` | ${parsedObject[key]}`;
      }
    });

    return recordInfo;
  };

  const fetchMachineRequests = async (filterParams = '', page = '1', per_page = '10') => {
    setMachineRequestsLoading(true);

    const cachedTimePeriod = localStorage.getItem('timePeriod') || '';
    const cachedStartDate = localStorage.getItem('startDate');
    const cachedEndDate = localStorage.getItem('endDate');

    let timeParams = {};

    if (cachedStartDate && cachedEndDate) {
      timeParams =
        cachedTimePeriod !== 'Custom'
          ? { time_period: cachedTimePeriod || '' }
          : {
              dateandtimestart: cachedStartDate || '',
              dateandtimeend: cachedEndDate || '',
            };
    } else {
      timeParams = { time_period: cachedTimePeriod || '' };
    }

    const params = new URLSearchParams({
      page: page,
      per_page: per_page,
      ...Object.fromEntries(new URLSearchParams(filterParams)),
      ...timeParams,
    }).toString();

    axios
      .get<string, any>(`api/companies/0/machine_requests/?${params}`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      })
      .then((response) => {
        setMachineRequests(response.data.results);
        setTotalMachineRequestsCount(response.data.pagination.total_count);

        setMachineRequestsLoading(false);
      })
      .catch((error) => {
        setErrorMessage(error?.response?.data?.Message);
        setMachineRequests([]);
        setMachineRequestsLoading(false);
      });
  };

  const fetchMachineRequest = async (machineRequestId) => {
    setMachineRequestLoading(true);

    axios
      .get<string, any>(`api/companies/0/machine_requests/${machineRequestId}`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      })
      .then((response) => {
        setMachineRequest(response.data.result);
        setMachineRequestLoading(false);
      })
      .catch((error) => {
        setErrorMessage(error?.response?.data?.Message);
        setMachineRequest(null);
        setMachineRequestLoading(false);
      });
  };

  const fetchMachineRequestRecords = async (
    machineRequestId = '',
    filterParams = {},
    page = '1',
    per_page = '50',
    onSuccess?: () => void,
  ) => {
    if (onSuccess) {
      setMachineRequestRecordsLoading(false);
    } else {
      setMachineRequestRecordsLoading(true);
    }

    // Clean filterParams to omit empty values here
    const cleanedFilters = Object.fromEntries(
      Object.entries(filterParams).filter(([, value]) => value !== null && value !== ''),
    );

    const params = new URLSearchParams({
      page: page,
      per_page: per_page,
      ...cleanedFilters,
    }).toString();

    axios
      .get<string, any>(`api/companies/0/machine_requests/${machineRequestId}/machine_request_records/?${params}`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      })
      .then((response) => {
        const modifiedRecords = response.data.results.map((record) => ({
          ...record,
          record_info: computeRecordInfo(record),
        }));

        setTotalMachineRequestRecordsCount(response.data.pagination.total_count);
        setHasPendingRecords(response.data.has_pending_records);
        setMachineRequestRecords(modifiedRecords);

        if (onSuccess) {
          onSuccess();
        } else {
          setMachineRequestRecordsLoading(false);
        }
      })
      .catch((error) => {
        setErrorMessage(error?.response?.data?.Message);
        setMachineRequestRecords(null);
        setMachineRequestRecordsLoading(false);
      });
  };

  const fetchMachineRequestRecord = async (machineRequestId = '', machine_request_record_id) => {
    setMachineRequestRecordLoading(true);

    axios
      .get<string, any>(
        `api/companies/0/machine_requests/${machineRequestId}/machine_request_records/${machine_request_record_id}`,
        {
          headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
        },
      )
      .then((response) => {
        setMachineRequestRecord(response.data.result);
        setMachineRequestRecordLoading(false);
      })
      .catch((error) => {
        setErrorMessage(error?.response?.data?.Message);
        setMachineRequestRecord(null);
        setMachineRequestRecordLoading(false);
      });
  };

  return (
    <MachineRequestsContext.Provider
      value={{
        fetchMachineRequests,
        machineRequests,
        machineRequestsLoading,
        totalMachineRequestsCount,
        hasPendingRecords,

        fetchMachineRequest,
        machineRequest,
        machineRequestLoading,

        fetchMachineRequestRecords,
        machineRequestRecords,
        machineRequestRecordsLoading,
        totalMachineRequestRecordsCount,

        fetchMachineRequestRecord,
        machineRequestRecord,
        machineRequestRecordLoading,

        errorMessage,
      }}
    >
      {children}
    </MachineRequestsContext.Provider>
  );
};

export { MachineRequestsContextProvider, MachineRequestsContextConsumer, MachineRequestsContext };
