import React from 'react';
import axios from '../../../modules/utils/axios.utils';

interface AutoBulkFiltersContextInterface {
  fetchFilterOptions?: (filterParams?: string) => Promise<void>;
  filterOptions?: Record<string, string[]>;
  filterOptionsLoading?: boolean;
  transactionCharts?: Record<string, any>;
  transactionChartsLoading?: boolean;
  errorMessage?: string;

  convertFilterValuesToUrlParams?: (machines: string[], customFilters: any) => string;

  convertProductFilterValuesToUrlParams?: (
    startDate: string,
    endDate: string,
    machines: string[],
    customFilters: any,
  ) => string;
}

const AutoBulkFiltersContext = React.createContext<AutoBulkFiltersContextInterface>({});

const AutoBulkFiltersContextConsumer = AutoBulkFiltersContext.Consumer;
const AutoBulkFiltersContextProvider: React.FC = ({ children }) => {
  const [filterOptions, setFilterOptions] = React.useState<Record<string, string[]>>({});
  const [filterOptionsLoading, setFilterOptionsLoading] = React.useState<boolean>(false);

  const [errorMessage, setErrorMessage] = React.useState<string>('');

  const fetchFilterOptions = async (filterParams = '') => {
    setErrorMessage('');
    setFilterOptionsLoading(true);

    const cachedMachines = JSON.parse(localStorage.getItem('autoBulkMachines') || '[]');
    const cachedStartDate = localStorage.getItem('startDate');
    const cachedEndDate = localStorage.getItem('endDate');

    const params = new URLSearchParams({
      work_order_start_date_after: cachedStartDate,
      work_order_start_date_before: cachedEndDate,
      machine: cachedMachines.join(),
      ...Object.fromEntries(new URLSearchParams(filterParams)),
    }).toString();

    axios
      .get<string, any>(`api/autobulk/analytics/filters/?${params}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
        },
      })
      .then((response) => {
        setFilterOptions({
          customer_names_list: response.data.customer_names_list,
          farm_notes_list: response.data.farm_notes_list,
          field_notes_list: response.data.field_notes_list,
          product_name_list: response.data.product_name_list,
          product_type_list: response.data.product_type_list,
          sources_list: response.data.sources_list,
        });
        setFilterOptionsLoading(false);
      })
      .catch(() => {
        setFilterOptionsLoading(false);
        setErrorMessage('Something went wrong. Please reload the page.');
      });
  };

  const fetchProductFilterOptions = async (filterParams = '') => {
    setFilterOptionsLoading(true);

    const cachedMachines = JSON.parse(localStorage.getItem('autoBulkMachines') || '[]');
    const cachedStartDate = localStorage.getItem('startDate');
    const cachedEndDate = localStorage.getItem('endDate');

    const params = new URLSearchParams({
      transaction__work_order_start_date_after: cachedStartDate,
      transaction__work_order_start_date_before: cachedEndDate,
      machine: cachedMachines.join(),
      ...Object.fromEntries(new URLSearchParams(filterParams)),
    }).toString();

    axios
      .get<string, any>(`api/autobulk/analytics/transactions/product_filters/?${params}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
        },
      })
      .then((response) => {
        setFilterOptions(response.data);
        setFilterOptionsLoading(false);
      })
      .catch(() => {
        setFilterOptionsLoading(false);
        setErrorMessage('Something went wrong. Please reload the page.');
      });
  };

  const convertFilterValuesToUrlParams = (machines: string[], customFilters: any) => {
    let filterValues = '';

    if (machines) {
      filterValues += `machine=${machines?.join()}&`;
    }

    if (customFilters?.customers?.length > 0) {
      filterValues += `customer_name=${encodeURIComponent(customFilters.customers?.join('|~|'))}&`;
    }

    if (customFilters?.farm?.length > 0) {
      filterValues += `work_order_farm_notes=${encodeURIComponent(customFilters.farm?.join('|~|'))}&`;
    }

    if (customFilters?.field?.length > 0) {
      filterValues += `work_order_field_notes=${encodeURIComponent(customFilters.field?.join('|~|'))}&`;
    }

    if (customFilters?.products?.length > 0) {
      filterValues += `products=${encodeURIComponent(customFilters.products?.join('|~|'))}&`;
    }

    if (customFilters?.sources?.length > 0) {
      filterValues += `sources=${encodeURIComponent(customFilters.sources?.join('|~|'))}&`;
    }

    if (customFilters?.product_types?.length > 0) {
      filterValues += `product_types=${encodeURIComponent(customFilters.product_types?.join('|~|'))}&`;
    }

    if (customFilters?.work_order_name?.length > 0) {
      filterValues += `work_order_name=${encodeURIComponent(customFilters.work_order_name)}&`;
    }

    return filterValues;
  };

  return (
    <AutoBulkFiltersContext.Provider
      value={{
        fetchFilterOptions,
        filterOptions,
        filterOptionsLoading,
        errorMessage,
        convertFilterValuesToUrlParams,
        // convertProductFilterValuesToUrlParams,
      }}
    >
      {children}
    </AutoBulkFiltersContext.Provider>
  );
};

export { AutoBulkFiltersContextProvider, AutoBulkFiltersContextConsumer, AutoBulkFiltersContext };
