import styled from 'styled-components';

const ModalFooter = styled.div`
  flex: 0 1 60px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  //border-radius: 0 0 2px 2px;
  background-color: #fff;
  padding: 10px 10px;

  .buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    Button {
      margin-left: 10px;
    }
  }
`;

export default ModalFooter;
