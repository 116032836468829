/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Card, CardContent, Typography, Grid } from '@mui/material';
import { Machine } from '../model';
import DataTable from '../../dataTable/DataTable/DataTable';
import columns from '../constants/columns';
import { AuthContext } from '../../auth/contexts/AuthContext';
import { MachinesContext } from '../contexts/MachinesContext';
import AdminMachineFilterBar from '../components/AdminMachineFilterBar';

import filters from '../constants/adminMachineFilters';

const AdminMachinesList: React.FunctionComponent = () => {
  const history = useHistory();
  const { fetchAdminMachines, fetchExportJson, exportJson, adminMachines, machinesLoading } =
    React.useContext(MachinesContext);
  const { currentUserHasAdminPermission } = React.useContext(AuthContext);
  const { filterOptions, fetchFilterOptions } = React.useContext(MachinesContext);

  const [filterValues, setFilterValues] = React.useState({
    subscription_active: 'status__all',
    last_online: 'status__all',
  });

  React.useEffect(() => {
    fetchExportJson();
    fetchAdminMachines();
    fetchFilterOptions();
  }, []);

  return (
    <>
      <Grid justifyContent="space-between" container spacing={3}>
        <Grid item>
          <Typography variant="h1" component="h1">
            Admin Machine and Access Management
          </Typography>
          <br />
        </Grid>
        {/* <Grid item>
          <CSVLink data={exportJson} filename="MACHINES.csv" style={{ textDecoration: 'none' }}>
            <Button variant="contained" color="primary">
              Export Machines
            </Button>
          </CSVLink>
        </Grid> */}
      </Grid>
      <br />
      <AdminMachineFilterBar
        filters={filters as any}
        filterValues={filterValues}
        setFilterValues={setFilterValues}
        filterChoices={filterOptions}
      />
      <br />
      <Card>
        <CardContent>
          <Grid justifyContent="space-between" container spacing={0}>
            <Grid item style={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="h1" component="h1" style={{ fontSize: '22px' }}>
                Machines List
              </Typography>
            </Grid>
            <Grid item>
              {currentUserHasAdminPermission('ksi_admin') && (
                <Button
                  style={{ marginLeft: 7 }}
                  variant="contained"
                  color="primary"
                  onClick={() => history.push(`/admin/machine_management/new`)}
                >
                  Create Machine
                </Button>
              )}
            </Grid>
          </Grid>
          <br />
          <DataTable
            noHeader
            columns={columns}
            data={adminMachines}
            onRowClicked={(row: Machine) => history.push(`/admin/machine_management/${row.machine_id}`)}
            defaultSortField="order_complete_date_and_time_utc"
            defaultSortAsc={false}
            striped
            highlightOnHover
            pointerOnHover
            pagination
            paginationPerPage={10}
            paginationRowsPerPageOptions={[10, 25, 50]}
            progressPending={machinesLoading}
          />
        </CardContent>
        <br />
      </Card>
    </>
  );
};

export default AdminMachinesList;
