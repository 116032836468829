import React from 'react';
import { Button, Grid, GridSize } from '@mui/material';
import { SelectOption } from '../../shared/model';
import FormInput from '../../shared/form/FormInput';
import { CompaniesContext } from '../contexts/CompaniesContext';
import SelectInput from '../../shared/form/SelectInput';

export interface FilterProps {
  label: string;
  pluralLabel: string;
  selector: string;
  width: boolean | GridSize;
  type: string;
  optionSelector: string;
  options?: SelectOption[];
}

interface CompanyFilterBarProps {
  filters?: FilterProps[];
  filterValues?: any;
  setFilterValues?: any;
  filterChoices?: any;
}

const CompanyFilterBar: React.FC<CompanyFilterBarProps> = (props) => {
  const { filters, filterValues, setFilterValues, filterChoices } = props;

  const { fetchCompanies, company, companiesLoading } = React.useContext(CompaniesContext);

  const convertFilters = () => {
    return Object.entries(filterValues)
      .map(([key, value]) => (value !== 'status__all' ? `${key}=${value}&` : ''))
      .join('');
  };

  const handleApplyFilters = async () => {
    const filterParam = convertFilters();
    fetchCompanies(filterParam);
  };

  const handleFiltersKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      handleApplyFilters();
    }
  };

  return (
    <Grid container spacing={1} alignItems="flex-start" onKeyDown={handleFiltersKeyPress}>
      <Grid item xs={12} md={2}>
        <FormInput
          value={filterValues?.name}
          onChange={(value) => setFilterValues({ ...filterValues, name: value })}
          label="Company Name"
        />
      </Grid>
      <Grid item xs={12} md={2}>
        <SelectInput
          label="Status"
          value={filterValues?.status}
          onChange={(value) => setFilterValues({ ...filterValues, status: value })}
          defaultChoice={{ label: 'All', value: 'status__all' }}
          choices={[
            { label: 'Active', value: 'true' },
            { label: 'Inactive', value: 'false' },
          ]}
        />
      </Grid>
      <Grid item xs={12} md={2}>
        <Button
          variant="contained"
          color="primary"
          style={{ color: '#fff', marginTop: 19 }}
          disableElevation
          onClick={() => {
            handleApplyFilters();
          }}
        >
          Apply
        </Button>
      </Grid>
    </Grid>
  );
};

export default CompanyFilterBar;
