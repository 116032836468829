import React from 'react';
import { Button, Grid, GridSize } from '@mui/material';
import { SelectOption } from '../../../modules/shared/model';
import MachineAutocompleteFilter from '../../../modules/shared/form/MachineAutocompleteFilter';
import { MachinesContext } from '../../../modules/machinesAdminManagement/contexts/MachinesContext';

export interface FilterProps {
  label: string;
  pluralLabel: string;
  selector: string;
  width: boolean | GridSize;
  type: string;
  optionSelector: string;
  options?: SelectOption[];
}

interface RealTimeActivityFilterBarProps {
  machineChoices?: any;
  applyFilter?: (filters: string) => void;
}

const RealTimeActivityFilterBar: React.FC<RealTimeActivityFilterBarProps> = (props) => {
  const { applyFilter, machineChoices } = props;
  const [machines, setMachines] = React.useState<string[]>(
    JSON.parse(localStorage.getItem('autoTreatMachines') || '[]'),
  );
  const { machinesLoading } = React.useContext(MachinesContext);

  React.useEffect(() => {
    localStorage.setItem('autoTreatMachines', JSON.stringify(machines));
  }, [machines]);

  const handleApplyFilters = () => {
    const filterHeaders = `machine${machines?.join()}&`;
    applyFilter(filterHeaders);
  };

  const handleFiltersKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      handleApplyFilters();
    }
  };

  return (
    <Grid container spacing={1} alignItems="flex-start" onKeyDown={handleFiltersKeyPress}>
      <Grid item xs={12}>
        <Grid container spacing={1} alignItems="flex-start">
          <Grid item>
            <MachineAutocompleteFilter
              label="Machines"
              loading={machinesLoading}
              pluralLabel="Machines"
              choices={machineChoices || []} // needed to not fail initial load
              setValue={(value) => {
                setMachines([].concat(value));
              }}
              value={machines as string[]}
            />
          </Grid>

          <Grid item>
            <Button
              variant="contained"
              color="primary"
              style={{ color: '#fff', marginTop: 19 }}
              disableElevation
              onClick={() => {
                handleApplyFilters();
              }}
            >
              Apply
            </Button>
            <span style={{ marginRight: 7 }} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default RealTimeActivityFilterBar;
