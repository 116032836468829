/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { format } from 'date-fns';
import { Typography, Grid } from '@mui/material';
import SideBarInformationField from '../../../modules/shared/form/SideBarInformationField';

interface TransactionDetailProps {
  transaction?: any;
}

const TransactionDetails: React.FunctionComponent<TransactionDetailProps> = (props) => {
  const { transaction } = props;

  const formatedDate = (date) => {
    return date ? format(new Date(`${date}Z`), 'MM/dd/yyyy hh:mm aaa') : '';
  };

  return (
    <>
      <Grid justifyContent="space-between" container spacing={3}>
        <Grid item>
          <Typography variant="h2" component="h2" style={{ marginTop: '-12px' }}>
            {`Transaction Details: ${transaction?.work_order_name}`}
          </Typography>
        </Grid>
      </Grid>
      <br />
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <SideBarInformationField label="Order Name:" value={transaction?.work_order_name} />
        </Grid>
        <Grid item xs={12}>
          <SideBarInformationField
            label={`Processed Date (${
              new Date().toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2]
            }):`}
            value={formatedDate(transaction?.work_order_complete_date)}
          />
        </Grid>
        <Grid item xs={12}>
          <SideBarInformationField label="Processed At:" value={transaction?.machine_name_with_serial} />
        </Grid>
        <Grid item xs={12}>
          <SideBarInformationField label="Comment:" value={transaction?.work_order_comment} />
        </Grid>
        <Grid item xs={12}>
          <SideBarInformationField label="Customer Name:" value={transaction?.customer_name} />
        </Grid>
        <Grid item xs={12}>
          <SideBarInformationField label="Farm:" value={transaction?.work_order_farm_notes} />
        </Grid>
        <Grid item xs={12}>
          <SideBarInformationField label="Field:" value={transaction?.work_order_field_notes} />
        </Grid>
        <Grid item xs={12}>
          <SideBarInformationField label="Area:" value={transaction?.work_order_area_total} />
        </Grid>
      </Grid>
    </>
  );
};

export default TransactionDetails;
