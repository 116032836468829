import React, { useContext } from 'react';
import { Grid } from '@mui/material';
import { SelectOption } from '../../shared/model';
import FormInput from '../../shared/form/FormInput';
import MachineAutocompleteFilter from '../../shared/form/MachineAutocompleteFilter';
import { MachinesContext } from '../../machinesAdminManagement/contexts/MachinesContext';

export interface FilterProps {
  label: string;
  pluralLabel: string;
  selector: string;
  width: any;
  type: string;
  optionSelector: string;
  options?: SelectOption[];
}

interface NotificationFilterBarProps {
  filterValues?: any;
  setFilterValues?: any;
  machineChoices?: any;
  applyFilter?: (filters: string) => void;
}

const NotificationFilterBar: React.FC<NotificationFilterBarProps> = (props) => {
  const { filterValues, setFilterValues, applyFilter, machineChoices } = props;
  const [name, setNotificationNameFilter] = React.useState<string>(sessionStorage.getItem('name') || '');
  const [product, setNotificationProductFilter] = React.useState<string>(sessionStorage.getItem('product') || '');

  const { machinesLoading } = useContext(MachinesContext);

  const [machines, setMachines] = React.useState<string[]>(JSON.parse(localStorage.getItem('commonMachines') || '[]'));

  React.useEffect(() => {
    localStorage.setItem('commonMachines', JSON.stringify(machines));
  }, [machines]);

  React.useEffect(() => {
    sessionStorage.setItem('name', name);
  }, [name]);

  React.useEffect(() => {
    sessionStorage.setItem('product', product);
  }, [product]);

  return (
    <Grid container spacing={1} alignItems="flex-start">
      <Grid item xs={12}>
        <Grid container spacing={1} alignItems="flex-start">
          <Grid item>
            <MachineAutocompleteFilter
              label="Machines"
              loading={machinesLoading}
              pluralLabel="Machines"
              choices={machineChoices}
              setValue={(value) => {
                setMachines([].concat(value));
              }}
              value={machines as string[]}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <FormInput
              label="Name"
              value={filterValues.notificationName}
              onChange={(value) => setFilterValues({ ...filterValues, notificationName: value })}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <FormInput
              label="Product"
              value={filterValues.product}
              onChange={(value) => setFilterValues({ ...filterValues, product: value })}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default NotificationFilterBar;
