import Axios from 'axios';
import { v4 as uuidv4 } from 'uuid'; // Assuming you have uuid library for generating unique IDs

const API = Axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  timeout: 120000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

// Function to save request and response details to sessionStorage
function saveRequestToSessionStorage(details) {
  const existingRequests = JSON.parse(sessionStorage.getItem('networkRequests')) || [];

  existingRequests.push(details);

  if (existingRequests.length > 50) {
    existingRequests.shift(); // Remove the oldest request (from the beginning)
  }

  sessionStorage.setItem('networkRequests', JSON.stringify(existingRequests));
}

API.interceptors.request.use(
  (config) => {
    const fullUrl = `${config.baseURL}${config.url}`;
    const requestId = uuidv4(); // Generate a unique ID for the request

    config.headers['X-Request-ID'] = requestId; // Attach the unique ID to the request headers

    // Check if the request is for the 'api/submit_api_logs/' endpoint and skip if so
    if (!fullUrl.includes('api/submit_api_logs/')) {
      const requestDetails = {
        network_request_id: requestId,
        method: config.method?.toUpperCase() || 'GET',
        url: fullUrl,
        headers: config.headers,
        data: config.data ? JSON.stringify(config.data) : null,
        timestamp: new Date().toISOString(),
        type: 'request',
      };

      saveRequestToSessionStorage(requestDetails);
    }

    return config;
  },
  (error) => {
    console.error('Request error:', error);
    return Promise.reject(error);
  },
);

API.interceptors.response.use(
  (response) => {
    const fullUrl = `${response.config.baseURL}${response.config.url}`;
    const requestId = response.config.headers['X-Request-ID']; // Retrieve the unique ID from the request headers

    let first_record = '';
    let total_record_count = '';

    try {
      // ignore the ts error on the next line cuz I am not adding a type interface and screwing up some fringe part of the app that I didn't test.
      // @ts-ignore
      first_record = response.data.results[0];
      // @ts-ignore
      total_record_count = response.data.count;
    } catch {
      // Do nothing
    }

    // Check if the response is for the 'api/submit_api_logs/' endpoint and skip if so
    if (!fullUrl.includes('api/submit_api_logs/')) {
      const responseDetails = {
        network_request_id: requestId, // Ensure the response details include the same unique ID
        method: response.config.method?.toUpperCase() || 'GET',
        url: fullUrl,
        headers: response.headers,
        example_data: first_record ? JSON.stringify(first_record) : null,
        result_count: total_record_count,
        status: response.status,
        statusText: response.statusText,
        timestamp: new Date().toISOString(),
        type: 'response',
      };

      saveRequestToSessionStorage(responseDetails);
    }

    return response;
  },
  (error) => {
    console.error('Response error:', error);
    if (error.response && error.response.status === 401 && error.response.data.detail === 'Token has expired.') {
      window.dispatchEvent(new CustomEvent('logoutRequired'));
    }
    return Promise.reject(error);
  },
);

export default API;
