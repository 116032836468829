/* eslint-disable react-hooks/exhaustive-deps, react/jsx-props-no-spreading */
import React from 'react';
import { Button, Grid, Typography } from '@mui/material';
import FormInput from '../../../modules/shared/form/FormInput';
import SelectInput from '../../../modules/shared/form/SelectInput';
import { SeedSourcesAutoTreatContext } from '../contexts/SeedSourcesAutoTreatContext';
import { SeedSourcesAutoTreatFormValues } from '../model';
import { SeedAutoTreat } from '../../seeds/model';
import seedSelectOptions from '../../seeds/constants/seedSelectOptions';
import { SeedsAutoTreatContext } from '../../seeds/contexts/SeedsAutoTreatContext';

interface SeedSourceAutoTreatFormProps {
  seedSource: any;
  machineSerial?: string;
  onSuccess?: () => void;
  onBack?: () => void;
}

const SeedSourceAutoTreatForm: React.FC<SeedSourceAutoTreatFormProps> = (props) => {
  const { seedSource, machineSerial, onSuccess, onBack } = props;
  const { updateSeedSourceAutoTreat, seedSourceSubmitting, activeMachine, seedSourcesOptions } =
    React.useContext(SeedSourcesAutoTreatContext);
  const { fetchOptions, fetchSeedOptions, options } = React.useContext(SeedsAutoTreatContext);

  const [formValues, setFormValues] = React.useState<SeedSourcesAutoTreatFormValues>({});
  const [amountTypeChoices, setAmountTypeChoices] = React.useState([]);
  const [selectedSeedVariety, setSelectedSeedVariety] = React.useState<SeedAutoTreat>({});

  React.useEffect(() => {}, [formValues]);

  React.useEffect(() => {
    fetchSeedOptions(localStorage.getItem('machineManagementAutoTreatMachine'));
    fetchOptions();
  }, []);

  React.useEffect(() => {
    if (selectedSeedVariety) {
      const amountTypesFilterArray =
        seedSelectOptions?.crop_data?.[
          Object.prototype.hasOwnProperty.call(seedSelectOptions.crop_data, selectedSeedVariety.crop_id)
            ? selectedSeedVariety.crop_id
            : '7'
        ]?.amount_types;

      if (options && options.amount_type_choices && Array.isArray(options.amount_type_choices)) {
        const filteredAmountTypes = options.amount_type_choices.filter(
          (option) => amountTypesFilterArray && amountTypesFilterArray.includes(option.value),
        );
        setAmountTypeChoices(filteredAmountTypes);
      }
    }
  }, [selectedSeedVariety, options]);

  const findSeedVarietyDetails = () => {
    const variety = seedSourcesOptions.find((v) => v.value === formValues.seed_variety_id);

    return variety
      ? { description: variety.seed_variety_desc, crop_name: variety.crop_name }
      : { description: '', crop_name: '' };
  };

  const handleSeedVarietyChange = (value) => {
    const matchingSeedSource = seedSourcesOptions.find((option) => option.seed_variety_id === value);
    setSelectedSeedVariety(matchingSeedSource);

    setFormValues({
      ...formValues,
      seed_variety_id: value,
      seed_lot_data: matchingSeedSource?.seed_lot_data || '',
      shipment_number: matchingSeedSource?.shipment_number || '',
      seed_origin: matchingSeedSource?.seed_origin || '',
      seed_germ: matchingSeedSource?.seed_germ || '',
      seed_test_date: matchingSeedSource?.seed_test_date || '',
      seed_amount_type_id: matchingSeedSource?.seed_amount_type_id || '',
      unit_size_wt: matchingSeedSource?.unit_size_wt || '',
      unit_size_cnt: matchingSeedSource?.unit_size_cnt || '',
      seed_cnt_per_scale_uow: matchingSeedSource?.seed_cnt_per_scale_uow || '',
    });
  };

  const handleUpdate = () => {
    updateSeedSourceAutoTreat(formValues);
  };

  React.useEffect(() => {
    setFormValues({
      seed_source_id: seedSource?.seed_source_id,
      seed_source: seedSource?.seed_source,
      seed_source_location: seedSource?.seed_source_location,
      seed_source_capacity: seedSource?.seed_source_capacity,
      seed_source_amount: seedSource?.seed_source_amount,
      seed_variety_id: seedSource?.seed_variety_id,
      seed_lot_data: seedSource?.seed_lot_data,
      shipment_number: seedSource?.shipment_number,
      seed_origin: seedSource?.seed_origin,
      seed_germ: seedSource?.seed_germ,
      seed_test_date: seedSource?.seed_test_date,
      seed_notes: seedSource?.seed_notes,
      seed_amount_type_id: seedSource?.seed_amount_type_id,
      seed_amount_type: seedSource?.seed_amount_type,
      unit_size_wt: seedSource?.unit_size_wt,
      unit_size_cnt: seedSource?.unit_size_cnt,
      seed_cnt_per_scale_uow: seedSource?.seed_cnt_per_scale_uow,
      date_and_time: seedSource?.date_and_time,
    });
  }, [seedSource]);

  return (
    <Grid container>
      <Grid item xs={8}>
        <form noValidate autoComplete="off">
          <Grid container spacing={3}>
            <Grid item xs={5}>
              <FormInput
                value={formValues?.seed_source}
                onChange={(value) => setFormValues({ ...formValues, seed_source: value })}
                label="Seed Source"
                disabled
              />
            </Grid>
            <Grid item xs={5}>
              <FormInput
                value={formValues?.seed_source_location}
                onChange={(value) => setFormValues({ ...formValues, seed_source_location: value })}
                label="Location"
                disabled
              />
            </Grid>
            <Grid item xs={5}>
              <FormInput
                value={formValues?.seed_source_capacity}
                onChange={(value) => setFormValues({ ...formValues, seed_source_capacity: value })}
                label="Capacity"
                disabled
              />
            </Grid>
            <Grid item xs={5}>
              <FormInput
                value={formValues?.seed_source_amount}
                onChange={(value) => setFormValues({ ...formValues, seed_source_amount: value })}
                label="Inventory"
              />
            </Grid>
            <Grid item xs={5}>
              <SelectInput
                value={formValues?.seed_variety_id}
                onChange={(value) => handleSeedVarietyChange(value)}
                label="Variety"
                choices={seedSourcesOptions}
              />
              <Typography variant="body2" style={{ marginTop: '8px' }}>
                Description: {findSeedVarietyDetails().description}
              </Typography>
              <Typography variant="body2">Crop Name: {findSeedVarietyDetails().crop_name}</Typography>
            </Grid>
            <Grid item xs={5}>
              <FormInput
                value={formValues?.seed_lot_data}
                onChange={(value) => setFormValues({ ...formValues, seed_lot_data: value })}
                label="Lot #"
              />
            </Grid>
            <Grid item xs={5}>
              <FormInput
                value={formValues?.shipment_number}
                onChange={(value) => setFormValues({ ...formValues, shipment_number: value })}
                label="Batch #"
              />
            </Grid>
            <Grid item xs={5}>
              <FormInput
                value={formValues?.seed_origin}
                onChange={(value) => setFormValues({ ...formValues, seed_origin: value })}
                label="Origin"
              />
            </Grid>
            <Grid item xs={5}>
              <FormInput
                value={formValues?.seed_germ}
                onChange={(value) => setFormValues({ ...formValues, seed_germ: value })}
                label="Germ"
              />
            </Grid>
            <Grid item xs={5}>
              <FormInput
                value={formValues?.seed_test_date}
                onChange={(value) => setFormValues({ ...formValues, seed_test_date: value })}
                label="Test Date"
              />
            </Grid>
            <Grid item xs={5}>
              <FormInput
                value={formValues?.seed_notes}
                onChange={(value) => setFormValues({ ...formValues, seed_notes: value })}
                label="Notes"
              />
            </Grid>
            <Grid item xs={5}>
              <SelectInput
                value={formValues?.seed_amount_type_id}
                choices={amountTypeChoices}
                onChange={(value) => {
                  setFormValues({ ...formValues, seed_amount_type_id: value });
                }}
                label="Seed Source Amount Type"
                required
              />
            </Grid>
            {formValues?.seed_amount_type_id === '3' ? (
              <>
                <Grid item xs={5}>
                  <FormInput
                    value={formValues?.seed_cnt_per_scale_uow}
                    onChange={(value) => setFormValues({ ...formValues, seed_cnt_per_scale_uow: value })}
                    label="Seed/lb"
                  />
                </Grid>
                <Grid item xs={5}>
                  <FormInput
                    value={formValues?.unit_size_cnt}
                    onChange={(value) => setFormValues({ ...formValues, unit_size_cnt: value })}
                    label="CNT Unit"
                  />
                </Grid>
              </>
            ) : (
              <Grid item xs={5}>
                <FormInput
                  value={formValues?.unit_size_wt}
                  onChange={(value) => setFormValues({ ...formValues, unit_size_wt: value })}
                  label="WT Unit (lb)"
                />
              </Grid>
            )}
          </Grid>
          <br />
          {activeMachine ? (
            <>
              <Button variant="contained" color="primary" disabled={seedSourceSubmitting} onClick={handleUpdate}>
                {seedSourceSubmitting ? 'Updating...' : 'Update'}
              </Button>
              <Button variant="contained" color="primary" onClick={onBack} style={{ marginLeft: 8 }}>
                Cancel
              </Button>
            </>
          ) : (
            <Button variant="contained" color="primary">
              Select A Machine
            </Button>
          )}
        </form>
      </Grid>
    </Grid>
  );
};

export default SeedSourceAutoTreatForm;
