import React from 'react';
import axios from '../../utils/axios.utils';

interface MachineRequestFiltersContextInterface {
  fetchMachineRequestFilterOptions?: () => Promise<void>;
  machineRequestFilterOptions?: any;
  machineRequestFilterOptionsLoading?: boolean;

  formatMachineRequestFilterValues?: (
    startDate: string,
    endDate: string,
    machines: string[],
    requestType: any,
    requestCategory: any,
    requestStatus: any,
  ) => string;
}

const MachineRequestFiltersContext = React.createContext<MachineRequestFiltersContextInterface>({});

const MachineRequestFiltersContextConsumer = MachineRequestFiltersContext.Consumer;
const MachineRequestFiltersContextProvider: React.FC = ({ children }) => {
  const [machineRequestFilterOptions, setMachineRequestFilterOptions] = React.useState({});
  const [machineRequestFilterOptionsLoading, setMachineRequestFilterOptionsLoading] = React.useState<boolean>(true);

  const fetchMachineRequestFilterOptions = async () => {
    setMachineRequestFilterOptionsLoading(true);

    axios
      .get<string, any>(`api/companies/0/machine_request_filter_options/`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      })
      .then((response) => {
        setMachineRequestFilterOptions({
          ...machineRequestFilterOptions,
          request_type_choices: response.data.result
            .filter((choice) => choice.type === 'Type')
            .map((choice) => ({ label: choice.label, value: choice.label })),
          request_category_choices: response.data.result
            .filter((choice) => choice.type === 'Category')
            .map((choice) => ({ label: choice.label, value: choice.label })),
        });
        setMachineRequestFilterOptionsLoading(false);
      })
      .catch((error) => {
        // setErrorMessage(error?.response?.data?.Message);
        setMachineRequestFilterOptions([]);
        setMachineRequestFilterOptionsLoading(false);
      });
  };

  const formatMachineRequestFilterValues = (
    startDate: string,
    endDate: string,
    machines: string[],
    requestType: any,
    requestCategory: any,
    requestStatus: any,
  ) => {
    let filterValues = '';

    filterValues += `machine=${machines?.join()}&`;
    filterValues += `dateandtimestart=${startDate}&`;
    filterValues += `dateandtimeend=${endDate}&`;

    if (requestType && requestType !== 'All') {
      filterValues += `request_type=${requestType}&`;
    }

    if (requestCategory && requestCategory !== 'All') {
      filterValues += `request_category=${requestCategory}&`;
    }

    if (requestStatus && requestStatus !== 'All') {
      filterValues += `status=${requestStatus}&`;
    }

    return filterValues;
  };

  return (
    <MachineRequestFiltersContext.Provider
      value={{
        fetchMachineRequestFilterOptions,
        formatMachineRequestFilterValues,
        machineRequestFilterOptions,
      }}
    >
      {children}
    </MachineRequestFiltersContext.Provider>
  );
};

export { MachineRequestFiltersContextProvider, MachineRequestFiltersContextConsumer, MachineRequestFiltersContext };
