import React from 'react';
import { Box, Grid, Button, Typography, Tabs, Tab } from '@mui/material';
import { useHistory, useParams } from 'react-router-dom';
import TabPanel from '../../shared/tabs/TabPanel';
import { AuthContext } from '../../auth/contexts/AuthContext';
import { CompaniesContext } from '../../companiesAdminManagement/contexts/CompaniesContext';
import { MachinesContext } from '../../machinesAdminManagement/contexts/MachinesContext';
import { UserMachineAccessContext } from '../../adminUsers/contexts/UserMachineAccessContext';
import Breadcrumbs from '../../navigation/components/Breadcrumbs';
import DownloadDatabaseForm from '../../machinesAdminManagement/components/DownloadDatabaseForm';

const AuditingDetail: React.FC = () => {
  const history = useHistory();
  const { machineSerial, tab } = useParams<Record<string, string>>();

  const { currentUserHasAdminPermission } = React.useContext(AuthContext);
  const { fetchMachine, machine, machineLoading } = React.useContext(MachinesContext);
  const { fetchCompanyOptions, companiesLoading } = React.useContext(CompaniesContext);
  const { fetchAllMachineAccessOptions } = React.useContext(UserMachineAccessContext);

  const [value, setValue] = React.useState(0);
  const [initialDataLoaded, setDataLoaded] = React.useState(false);
  const [users, setUsers] = React.useState<any[]>();
  const [externalEmail, setExternalEmail] = React.useState('');

  React.useEffect(() => {
    // fetchMachine(machineSerial);
    // fetchCompanyOptions();
    // fetchAllMachineAccessOptions();
  }, [machineSerial]);

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);

    if (newValue === 0) {
      window.history.pushState('', '', `/admin/machine_management/${machine.serial_number}/details`);
    } else if (newValue === 1) {
      window.history.pushState('', '', `/admin/machine_management/${machine.serial_number}/actions`);
    }
  };

  React.useEffect(() => {
    if (tab === 'details') {
      setValue(0);
    }
  }, [tab]);

  const links = [
    {
      href: '/admin/auditing',
      text: 'Internal Auditing',
    },
    {
      href: `/admin/machine_management/${machine.serial_number}`,
      text: `N/A`,
    },
  ];

  return (
    <div>
      <Grid justifyContent="space-between" container spacing={3}>
        <Grid item>
          <Breadcrumbs links={links} />
          <br />
        </Grid>
      </Grid>

      <div>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} aria-label="machine tabs">
            <Tab style={{ textTransform: 'capitalize' }} label="Page 1" />
            <Tab style={{ textTransform: 'capitalize' }} label="Page 2" />
            {currentUserHasAdminPermission('ksi_support') && (
              <Tab style={{ textTransform: 'capitalize' }} label="Page 3" />
            )}
            {currentUserHasAdminPermission('ksi_superadmin') && (
              <Tab style={{ textTransform: 'capitalize' }} label="Page 4" />
            )}
          </Tabs>
        </Box>
        <br />
        <TabPanel value={value} index={0}>
          <DownloadDatabaseForm machine={machine} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <DownloadDatabaseForm machine={machine} />
        </TabPanel>
        {currentUserHasAdminPermission('ksi_support') && (
          <TabPanel value={value} index={2}>
            <DownloadDatabaseForm machine={machine} />
          </TabPanel>
        )}
        {currentUserHasAdminPermission('ksi_superadmin') && (
          <TabPanel value={value} index={3}>
            <DownloadDatabaseForm machine={machine} />
          </TabPanel>
        )}
      </div>
    </div>
  );
};

export default AuditingDetail;
