/* eslint-disable react-hooks/exhaustive-deps, react/jsx-props-no-spreading */
import React from 'react';
import { Button, Grid } from '@mui/material';
import FormInput from '../../../modules/shared/form/FormInput';
import SelectInput from '../../../modules/shared/form/SelectInput';
import { CustomerAutoTreatFormValues } from '../model';
import { CustomersAutoTreatContext } from '../contexts/CustomersAutoTreatContext';
import { CustomersAutoTreatValidationsContext } from '../contexts/CustomersAutoTreatValidationsContext';

interface CustomerAutoTreatFormProps {
  customer: any;
  machineSerial?: string;
  onSuccess?: () => void;
  onBack?: () => void;
}

const CustomerAutoTreatForm: React.FC<CustomerAutoTreatFormProps> = (props) => {
  const { customer, machineSerial, onSuccess, onBack } = props;
  const {
    updateCustomerAutoTreat,
    createCustomerAutoTreat,
    deleteCustomerAutoTreat,
    customerDeleting,
    customerSubmitting,
    activeMachine,
  } = React.useContext(CustomersAutoTreatContext);
  const { validateCustomersAutoTreatFields, formErrors } = React.useContext(CustomersAutoTreatValidationsContext);
  const [formValues, setFormValues] = React.useState<CustomerAutoTreatFormValues>({});
  const [actionButtonDisabled, setActionButtonDisabled] = React.useState(false);

  const handleUpdate = () => {
    updateCustomerAutoTreat(customer.customer_id, formValues);
  };

  const handleCreate = () => {
    createCustomerAutoTreat(formValues, onSuccess);
  };

  const handleDelete = () => {
    deleteCustomerAutoTreat(formValues.customer_id);
  };

  React.useEffect(() => {
    const atCustomersFieldsValid = validateCustomersAutoTreatFields(formValues);
    setActionButtonDisabled(Boolean(!atCustomersFieldsValid));
  }, [formValues]);

  React.useEffect(() => {
    setFormValues({
      customer_id: customer?.customer_id,
      operation: customer?.operation,
      first_name: customer?.first_name,
      last_name: customer?.last_name,
      address1: customer?.address1,
      address2: customer?.address2,
      city: customer?.city,
      state: customer?.state,
      zip_code: customer?.zip_code,
      country: customer?.country,
      mobile_phone: customer?.mobile_phone,
      business_phone: customer?.business_phone,
      email: customer?.email,
      auto_email: customer?.auto_email || 'False',
      comment: customer?.comment,
      active: customer?.active || 'True',
    });
  }, [customer]);

  return (
    <Grid container>
      <Grid item xs={8}>
        <form noValidate autoComplete="off">
          <Grid container spacing={3}>
            <Grid item xs={5}>
              <FormInput
                value={formValues?.operation}
                onChange={(value) => setFormValues({ ...formValues, operation: value })}
                label="Operation"
                required
                errorMessage={formErrors.CustomerName}
              />
            </Grid>
            <Grid item xs={5}>
              <FormInput
                value={formValues?.first_name}
                onChange={(value) => setFormValues({ ...formValues, first_name: value })}
                label="First Name"
              />
            </Grid>
            <Grid item xs={5}>
              <FormInput
                value={formValues?.last_name}
                onChange={(value) => setFormValues({ ...formValues, last_name: value })}
                label="Last Name"
              />
            </Grid>
            <Grid item xs={5}>
              <FormInput
                value={formValues?.address1}
                onChange={(value) => setFormValues({ ...formValues, address1: value })}
                label="Address1"
              />
            </Grid>
            <Grid item xs={5}>
              <FormInput
                value={formValues?.address2}
                onChange={(value) => setFormValues({ ...formValues, address2: value })}
                label="Address2"
              />
            </Grid>
            <Grid item xs={5}>
              <FormInput
                value={formValues?.city}
                onChange={(value) => setFormValues({ ...formValues, city: value })}
                label="City"
              />
            </Grid>
            <Grid item xs={5}>
              <FormInput
                value={formValues?.state}
                onChange={(value) => setFormValues({ ...formValues, state: value })}
                label="State"
              />
            </Grid>
            <Grid item xs={5}>
              <FormInput
                value={formValues?.zip_code}
                onChange={(value) => setFormValues({ ...formValues, zip_code: value })}
                label="Zip Code"
              />
            </Grid>
            <Grid item xs={5}>
              <FormInput
                value={formValues?.country}
                onChange={(value) => setFormValues({ ...formValues, country: value })}
                label="Country"
              />
            </Grid>
            <Grid item xs={5}>
              <FormInput
                value={formValues?.mobile_phone}
                onChange={(value) => setFormValues({ ...formValues, mobile_phone: value })}
                label="Mobile Phone #"
              />
            </Grid>
            <Grid item xs={5}>
              <FormInput
                value={formValues?.business_phone}
                onChange={(value) => setFormValues({ ...formValues, business_phone: value })}
                label="Business Phone #"
              />
            </Grid>
            <Grid item xs={5}>
              <FormInput
                value={formValues?.email}
                onChange={(value) => setFormValues({ ...formValues, email: value })}
                label="Email"
              />
            </Grid>
            <Grid item xs={5}>
              <FormInput
                value={formValues?.comment}
                onChange={(value) => setFormValues({ ...formValues, comment: value })}
                label="Comment"
                errorMessage={formErrors.CustomerComment}
              />
            </Grid>
            <Grid item xs={5}>
              <SelectInput
                value={formValues?.auto_email}
                choices={[
                  { label: 'Yes', value: 'True' },
                  { label: 'No', value: 'False' },
                ]}
                onChange={(value) => setFormValues({ ...formValues, auto_email: value })}
                label="Auto Email Receipt"
              />
            </Grid>
            <Grid item xs={5}>
              <SelectInput
                value={formValues?.active}
                choices={[
                  { label: 'Yes', value: 'True' },
                  { label: 'No', value: 'False' },
                ]}
                onChange={(value) => setFormValues({ ...formValues, active: value })}
                label="Active"
              />
            </Grid>
          </Grid>
          <br />
          {activeMachine ? (
            <>
              {customer?.customer_id ? (
                <Button
                  variant="contained"
                  color="primary"
                  disabled={actionButtonDisabled || customerSubmitting || customerDeleting}
                  onClick={handleUpdate}
                >
                  {customerSubmitting ? 'Updating...' : 'Update'}
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  disabled={actionButtonDisabled || customerSubmitting || customerDeleting}
                  onClick={handleCreate}
                >
                  {customerSubmitting ? 'Creating...' : 'Create'}
                </Button>
              )}
              <Button
                variant="contained"
                color="primary"
                onClick={onBack}
                style={{ marginLeft: 8 }}
                disabled={customerDeleting}
              >
                Cancel
              </Button>
            </>
          ) : (
            <Button variant="contained" color="primary">
              Select A Machine
            </Button>
          )}
        </form>
      </Grid>
      {formValues?.customer_id && (
        <Grid item xs={4} style={{ display: 'flex', justifyContent: 'right' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleDelete}
            disabled={customerSubmitting || customerDeleting}
          >
            {customerDeleting ? 'Deleting...' : 'Delete'}
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export default CustomerAutoTreatForm;
