import React, { Dispatch, SetStateAction } from 'react';
import axios from '../../../modules/utils/axios.utils';
import { ProductAutoBulkFormValues } from '../model';
import { MACHINE_MANAGEMENT_RETRY_INTERVAL, MACHINE_MANAGEMENT_TOTAL_RETRIES } from '../../../modules/utils/env';
import { SnackBarContext } from '../../../modules/snackBar/contexts/SnackBarContext';

interface ProductsAutoBulkContextInterface {
  fetchCachedProductOptions?: (machineSerial: string) => Promise<void>;
  fetchProductOptions?: (machineSerial: string) => Promise<void>;

  productsOptions?: any;
  productsOptionsLoading?: boolean;

  fetchCachedProductsAutoBulk?: (machineSerial: string) => Promise<void>;

  fetchProductsAutoBulk?: (
    machineSerial: string,
    genericFilter: string,
    statusFilter: string,
    apiLinkedFilter: string,
    onSuccess?: () => void,
  ) => Promise<void>;
  productsRefreshedAt?: string;
  products?: any[];
  productsLoading?: boolean;

  createProductAutoBulk?: (formValues: ProductAutoBulkFormValues, redirectToProduct) => Promise<void>;

  updateProductAutoBulk?: (userId: string, formValues: ProductAutoBulkFormValues) => Promise<void>;
  productSubmitting?: boolean;
  setProductAutoBulkSubmitting?: Dispatch<SetStateAction<boolean>>;

  deleteProductAutoBulk?: (productId: string) => Promise<void>;
  productDeleting?: boolean;

  creatingPage?: boolean;
  setCreatingPage?: Dispatch<SetStateAction<boolean>>;
  detailPage?: boolean;
  setDetailPage?: Dispatch<SetStateAction<boolean>>;

  setActiveMachine?: Dispatch<SetStateAction<Record<string, string>>>;
  activeMachine?: Record<string, string>;

  setActiveProductAutoBulk?: Dispatch<SetStateAction<Record<string, string>>>;
  activeProductAutoBulk?: Record<string, string>;

  handleGenericFilterChange?: (genericFilter: string) => Promise<void>;
  genericFilter?: string;

  handleStatusFilterChange?: (statusFilter: string) => Promise<void>;
  statusFilter?: string;

  handleApiLinkedFilterChange?: (apiLinkedFilter: string) => Promise<void>;
  apiLinkedFilter?: string;

  setImportingPage?: any;
  importingPage?: any;

  setImportPreview?: any;
  importPreview?: any;

  setImportPreviewFailed?: any;
  importPreviewFailed?: any;

  setImportPreviewLoading?: any;
  importPreviewLoading?: boolean;
}

const ProductsAutoBulkContext = React.createContext<ProductsAutoBulkContextInterface>({});

const ProductsAutoBulkContextConsumer = ProductsAutoBulkContext.Consumer;
const ProductsAutoBulkContextProvider: React.FC = ({ children }) => {
  const { showErrorSnackBar, showSuccessSnackBar, showMachineManagementSnackBar } = React.useContext(SnackBarContext);

  const [products, setProductsAutoBulk] = React.useState([]);
  const [productsLoading, setProductsAutoBulkLoading] = React.useState(true);
  const [productsRefreshedAt, setProductsAutoBulkRefreshedAt] = React.useState<string>();
  const [product, setProductAutoBulk] = React.useState({});
  const [activeProductAutoBulk, setActiveProductAutoBulk] = React.useState({});
  const [productLoading, setProductAutoBulkLoading] = React.useState(true);
  const [productSubmitting, setProductAutoBulkSubmitting] = React.useState(false);
  const [productDeleting, setProductAutoBulkDeleting] = React.useState(false);
  const [creatingPage, setCreatingPage] = React.useState<boolean>(false);
  const [detailPage, setDetailPage] = React.useState<boolean>(false);
  const [activeMachine, setActiveMachine] = React.useState<Record<string, string>>({
    label: localStorage.getItem('machineManagementAutoBulkMachine') || 'Select A Machine',
    value: localStorage.getItem('machineManagementAutoBulkMachine') || 'Select A Machine',
  });
  const [productsOptions, setProductsOptions] = React.useState({});
  const [genericFilter, setGenericFilter] = React.useState<string>('');
  const [statusFilter, setStatusFilter] = React.useState<string>('All');
  const [apiLinkedFilter, setApiLinkedFilter] = React.useState<string>('All');

  const [importingPage, setImportingPage] = React.useState<boolean>(false);
  const [importPreview, setImportPreview] = React.useState([]);
  const [importPreviewFailed, setImportPreviewFailed] = React.useState([]);
  const [importPreviewLoading, setImportPreviewLoading] = React.useState(false);

  React.useEffect(() => {
    if (activeMachine?.value === 'Select A Machine') {
      setProductsAutoBulkLoading(false);
    }
  }, [activeMachine]);

  const fetchCachedProductOptions = async (machineSerial: string) => {
    const accessToken = sessionStorage.getItem('accessToken');

    axios
      .get<string, any>(`api/autobulk/onprem/products_options/${machineSerial}/`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((response) => {
        setProductsOptions({
          ...productsOptions,
          product_type_choices: response.data.results
            .filter((choice) => choice.category === 'product_type')
            .map((choice) => ({ label: choice.label, value: choice.value })),
          amount_type_choices: response.data.results
            .filter((choice) => choice.category === 'amount_type')
            .map((choice) => ({ label: choice.label, value: choice.value })),
        });
      })
      .catch((response) => {
        setProductsOptions({
          ...productsOptions,
          product_type_choices: [],
          amount_type_choices: [],
        });
      });
  };

  const fetchProductOptions = async (machineSerial: string) => {
    const accessToken = sessionStorage.getItem('accessToken');
    let retryCount = 0;

    axios
      .get<string, any>(`api/autobulk/onprem/products_options/?serial-number=${machineSerial}`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((res) => {
        const timer = window.setInterval(() => {
          axios
            .get<string, any>(`api/autobulk/onprem/products_options/${res.data.result}/`, {
              headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
            })
            .then((response) => {
              window.clearInterval(timer);
              setProductsOptions({
                ...productsOptions,
                product_type_choices: response.data.results
                  .filter((choice) => choice.category === 'product_type')
                  .map((choice) => ({ label: choice.label, value: choice.value })),
                amount_type_choices: response.data.results
                  .filter((choice) => choice.category === 'amount_type')
                  .map((choice) => ({ label: choice.label, value: choice.value })),
              });
            })
            .catch((error) => {
              retryCount += 1;

              if (retryCount > MACHINE_MANAGEMENT_TOTAL_RETRIES) {
                window.clearInterval(timer);
              }
            });
        }, MACHINE_MANAGEMENT_RETRY_INTERVAL);
      })
      .catch((error) => {
        setProductsOptions({
          ...productsOptions,
          product_type_choices: [],
          amount_type_choices: [],
        });
      });
  };

  const fetchCachedProductsAutoBulk = async (machineSerial: string) => {
    setProductsAutoBulkLoading(true);
    setProductsAutoBulkRefreshedAt(null);
    const retryCount = 0;

    axios
      .get<string, any>(`api/autobulk/onprem/products/${machineSerial}/`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      })
      .then((response) => {
        setProductsAutoBulkLoading(false);
        setProductsAutoBulk(response.data.results);
        setProductsAutoBulkRefreshedAt(response.data.created_at);
      })
      .catch((error) => {
        setProductsAutoBulkLoading(false);
        setProductsAutoBulk([]);
        setProductsAutoBulkRefreshedAt(null);
      });
  };

  const fetchProductsAutoBulk = async (
    machineSerial: string,
    genericFilter: string,
    statusFilter: string,
    apiLinkedFilter: string,
    onSuccess?: () => void,
  ) => {
    setProductsAutoBulkLoading(true);
    setProductsAutoBulkRefreshedAt(null);
    let retryCount = 0;

    axios
      .get<string, any>(
        `api/autobulk/onprem/products/?serial-number=${machineSerial}&generic-filter=${genericFilter}&status=${statusFilter}&api-linked=${apiLinkedFilter}`,
        {
          headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
        },
      )

      .then((res) => {
        const fetchTimer = window.setInterval(() => {
          axios
            .get<string, any>(`api/autobulk/onprem/products/${res.data.result}`, {
              headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
            })
            .then((response) => {
              window.clearInterval(fetchTimer);
              setProductsAutoBulkLoading(false);
              setProductsAutoBulk(response.data.results);
              setProductsAutoBulkRefreshedAt(response.data.created_at);

              if (onSuccess) {
                onSuccess();
              }
            })
            .catch(() => {
              if (retryCount > MACHINE_MANAGEMENT_TOTAL_RETRIES) {
                window.clearInterval(fetchTimer);
                setProductsAutoBulkLoading(false);
                setProductsAutoBulk([]);
                setProductsAutoBulkRefreshedAt(null);
              } else {
                retryCount += 1;
              }
            });
        }, MACHINE_MANAGEMENT_RETRY_INTERVAL);
      });
  };

  const updateProductAutoBulk = async (productId: string, formValues: ProductAutoBulkFormValues) => {
    setDetailPage(true);
    setProductAutoBulkSubmitting(true);
    setProductsAutoBulkLoading(true);
    showSuccessSnackBar('Sending update to the machine.');
    let retryCount = 0;

    axios
      .put<any, any>(
        `api/autobulk/onprem/products/0/?serial-number=${activeMachine?.value}`,
        { ...formValues },
        { headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` } },
      )
      .then((res) => {
        const timer = window.setInterval(() => {
          axios
            .get<string, any>(`api/autobulk/onprem/products/${res.data.result}/`, {
              headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
            })
            .then((response) => {
              window.clearInterval(timer);
              setProductAutoBulkLoading(false);
              showMachineManagementSnackBar(response.data.results[0]);
              fetchProductOptions(activeMachine?.value);
              fetchProductsAutoBulk(activeMachine?.value, '', '', '', () => {
                setProductAutoBulkLoading(false);
                setProductAutoBulkSubmitting(false);
              });
            })
            .catch(() => {
              if (retryCount > MACHINE_MANAGEMENT_TOTAL_RETRIES) {
                window.clearInterval(timer);
                showErrorSnackBar('No response from machine');
                setProductAutoBulkLoading(false);
                setProductAutoBulkSubmitting(false);

                setProductAutoBulk([]);
              } else {
                retryCount += 1;
              }
            });
        }, MACHINE_MANAGEMENT_RETRY_INTERVAL);
      });
  };

  const createProductAutoBulk = async (formValues: ProductAutoBulkFormValues, redirectToProduct = false) => {
    setProductAutoBulkSubmitting(true);
    setCreatingPage(true);
    setProductsAutoBulkLoading(true);
    showSuccessSnackBar('Sending record to the machine.');
    let retryCount = 0;

    axios
      .post<any, any>(
        `api/autobulk/onprem/products/?serial-number=${activeMachine?.value}`,
        { ...formValues },
        { headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` } },
      )
      .then((res) => {
        const timer = window.setInterval(() => {
          axios
            .get<string, any>(`api/autobulk/onprem/products/${res.data.result}/`, {
              headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
            })
            .then((response) => {
              window.clearInterval(timer);
              setProductAutoBulkLoading(false);
              showMachineManagementSnackBar(response.data.results[0]);
              fetchProductOptions(activeMachine?.value);
              fetchProductsAutoBulk(activeMachine?.value, '', '', '', () => {
                setProductAutoBulkLoading(false);
                setProductAutoBulkSubmitting(false);

                if (redirectToProduct) {
                  window.location.href = `/autobulk/machines/${activeMachine.value}/products/${response?.data?.results[0]?.record_id}`;
                }
              });
            })
            .catch(() => {
              if (retryCount > MACHINE_MANAGEMENT_TOTAL_RETRIES) {
                window.clearInterval(timer);
                showErrorSnackBar('No response from machine');
                setProductAutoBulkLoading(false);
                setProductAutoBulkSubmitting(false);
                setProductAutoBulk([]);
              } else {
                retryCount += 1;
              }
            });
        }, MACHINE_MANAGEMENT_RETRY_INTERVAL);
      });
  };

  const deleteProductAutoBulk = async (productId: string) => {
    setProductAutoBulkDeleting(true);
    showSuccessSnackBar('Sending update to the machine.');
    let retryCount = 0;

    axios
      .delete<any, any>(`api/autobulk/onprem/products/${productId}/?serial-number=${activeMachine?.value}`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      })
      .then((res) => {
        const timer = window.setInterval(() => {
          axios
            .get<string, any>(`api/autobulk/onprem/products/${res.data.result}/`, {
              headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
            })
            .then((response) => {
              window.clearInterval(timer);
              setProductAutoBulkLoading(false);
              showMachineManagementSnackBar(response.data.results[0]);
              if (response.data.results[0].status === 'Success') {
                setDetailPage(false);
              }
              setProductAutoBulkDeleting(false);
              fetchProductsAutoBulk(activeMachine?.value, '', '', '', () => {});
            })
            .catch((error) => {
              retryCount += 1;

              if (retryCount > MACHINE_MANAGEMENT_TOTAL_RETRIES) {
                window.clearInterval(timer);
                showErrorSnackBar('No response from machine');
                setProductAutoBulkLoading(false);
                setProductAutoBulkDeleting(false);
              }
            });
        }, MACHINE_MANAGEMENT_RETRY_INTERVAL);
      });
  };

  const handleGenericFilterChange = async (genericFilter: string) => {
    setGenericFilter(genericFilter);
  };

  const handleStatusFilterChange = async (statusFilter: string) => {
    setStatusFilter(statusFilter);
  };

  const handleApiLinkedFilterChange = async (apiLinkedFilter: string) => {
    setApiLinkedFilter(apiLinkedFilter);
  };

  return (
    <ProductsAutoBulkContext.Provider
      value={{
        fetchCachedProductOptions,
        fetchProductOptions,

        productsOptions,

        fetchCachedProductsAutoBulk,
        fetchProductsAutoBulk,
        products,
        productsRefreshedAt,
        productsLoading,

        createProductAutoBulk,
        updateProductAutoBulk,
        productSubmitting,
        setProductAutoBulkSubmitting,
        deleteProductAutoBulk,
        productDeleting,

        creatingPage,
        setCreatingPage,
        detailPage,
        setDetailPage,

        setActiveProductAutoBulk,
        activeProductAutoBulk,

        setActiveMachine,
        activeMachine,

        handleGenericFilterChange,
        genericFilter,
        handleStatusFilterChange,
        statusFilter,
        handleApiLinkedFilterChange,
        apiLinkedFilter,

        setImportingPage,
        importingPage,

        setImportPreview,
        importPreview,

        setImportPreviewLoading,
        importPreviewLoading,
      }}
    >
      {children}
    </ProductsAutoBulkContext.Provider>
  );
};

export { ProductsAutoBulkContextProvider, ProductsAutoBulkContextConsumer, ProductsAutoBulkContext };
