import React, { useEffect, useState, useContext } from 'react';
import { Autocomplete, TextField, Button, Grid, FormControlLabel, Checkbox } from '@mui/material';
import PolarisLabel from '../../shared/form/PolarisLabel';
import { CompaniesContext } from '../../companiesAdminManagement/contexts/CompaniesContext';
import FormInput from '../../shared/form/FormInput';
import SelectInput from '../../shared/form/SelectInput';
import { UsersContext } from '../contexts/UsersContext';
import { User, UserFormValues } from '../model';
import MachineAutocompleteInput from './MachineAutocompleteInput';
import { AuthContext } from '../../auth/contexts/AuthContext';

interface UserFormProps {
  user: User;
  creatingUser: boolean;
}

const UserForm: React.FC<UserFormProps> = (params) => {
  const { user, creatingUser } = params;

  const {
    updateUser,
    createUser,
    userSubmitting,
    formErrors,

    fetchUserMachinesByCompany,
    userMachinesByCompany,
    userMachinesByCompanyLoading,

    userPermissionGroups,
    userPermissionGroupsLoading,

    adminPermissionGroups,
    adminPermissionGroupsLoading,
  } = useContext(UsersContext);
  const { companyOptions } = React.useContext(CompaniesContext);

  const { currentUserHasAdminPermission } = React.useContext(AuthContext);

  const [formValues, setFormValues] = useState<UserFormValues>({});
  const [selectedMachines, setSelectedMachines] = useState<any[]>([]);
  const [combinedMachines, setCombinedMachines] = useState<any[]>([]);
  const [machinesFromUser, setMachinesFromUser] = useState<any[]>([]);
  const [companyName, setCompanyName] = useState<string>('');
  const [selectedCompanyOption, setSelectedCompanyOption] = useState<Record<string, string>>({});

  const [sendInvitationEmail, setSendInvitationEmail] = React.useState(false);
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);

  useEffect(() => {
    if (!creatingUser) {
      setFormValues({
        full_name: user?.full_name,
        email_address: user?.email_address,
        company: user?.company,
        contact_number: user?.contact_number,
        user_permission_group_id: user?.user_permission_group_id,
        admin_permission_group_id: user?.admin_permission_group_id,
      });
      setInitialLoadComplete(true);

      setSelectedMachines(user?.machine_access?.map((machine) => machine.serial_number));
      setCompanyName(user?.company_name_and_account);
      setSelectedCompanyOption(companyOptions.find((co) => co.value === user?.company_id));
      setMachinesFromUser(
        user?.machine_access?.map((machine) => ({
          label: machine.machine_name_with_serial,
          subscription_active: true,
          value: machine.serial_number,
        })) || [],
      );
    }
  }, [user]);

  useEffect(() => {
    const machinesFromCompany = (userMachinesByCompany || []).map((machine) => ({
      label: machine.machine_name_with_serial,
      subscription_active: true,
      value: machine.serial_number,
    }));

    const combinedMachineValues = new Set(
      [...machinesFromCompany, ...machinesFromUser].map((machine) => JSON.stringify(machine)),
    );
    const uniqueMachines = Array.from(combinedMachineValues).map((machine) => JSON.parse(machine));

    setCombinedMachines(uniqueMachines);
  }, [userMachinesByCompany]);

  useEffect(() => {
    if ((initialLoadComplete || creatingUser) && !validAdminEmail()) {
      setFormValues({ ...formValues, admin_permission_group_id: null });
    }
  }, [formValues?.email_address]);

  const refreshCompanyMachines = (companyName: string) => {
    const option = companyOptions.find((co) => co.label === companyName);
    fetchUserMachinesByCompany(option?.value);
  };

  const handleUpdate = () => {
    const serialNumbers = selectedMachines.map((machine) => machine);
    const updatedFormValues = { ...formValues, serial_numbers: serialNumbers, company: selectedCompanyOption?.value };
    updateUser(user.user_id, updatedFormValues);
  };

  const handleCreate = () => {
    const serialNumbers = selectedMachines.map((machine) => machine);
    const updatedFormValues = { ...formValues, serial_numbers: serialNumbers, company: selectedCompanyOption?.value };
    createUser(updatedFormValues);
  };

  const validAdminEmail = (): boolean => {
    return (
      formValues?.email_address?.toLowerCase().includes('@ksiedge') ||
      formValues?.email_address?.toLowerCase().includes('@nextlinklabs')
    );
  };

  return (
    <Grid container>
      <Grid item xs={10}>
        <Grid container rowSpacing={2} columnSpacing={24}>
          <Grid item xs={12} md={8}>
            <FormInput
              value={formValues?.full_name}
              errorMessage={formErrors?.full_name || formErrors?.name}
              onChange={(value) => setFormValues({ ...formValues, full_name: value })}
              label="Full Name"
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <FormInput
              value={formValues?.email_address?.toLowerCase()}
              errorMessage={formErrors?.email_address}
              onChange={(value) => setFormValues({ ...formValues, email_address: value?.toLowerCase() })}
              label="Email"
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <FormInput
              value={formValues?.contact_number}
              onChange={(value) => setFormValues({ ...formValues, contact_number: value })}
              label="Contact Number"
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <PolarisLabel label="Company" />
            <Autocomplete
              disablePortal
              value={selectedCompanyOption || null}
              onChange={(event: any) => {
                setSelectedCompanyOption(companyOptions.find((co) => co.label === event.target.textContent));
                refreshCompanyMachines(event.target.textContent);
                setCompanyName(event.target.textContent);
              }}
              getOptionLabel={(option) => option.label || ''}
              autoComplete={false}
              options={companyOptions}
              sx={{ width: '100%' }}
              renderInput={(inputParameters) => <TextField {...inputParameters} autoComplete="off" />}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <SelectInput
              value={formValues?.user_permission_group_id}
              loading={userPermissionGroupsLoading}
              choices={[
                { label: 'None', value: undefined },
                ...userPermissionGroups.map((group) => ({
                  label: group.description,
                  value: group.permission_group_id,
                })),
              ]}
              onChange={(value) => setFormValues({ ...formValues, user_permission_group_id: value })}
              label="User Permission Group"
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <SelectInput
              value={formValues?.admin_permission_group_id}
              loading={adminPermissionGroupsLoading}
              disabled={!validAdminEmail()}
              choices={[
                { label: 'None', value: undefined },
                ...adminPermissionGroups.map((group) => ({
                  label: group.description,
                  value: group.permission_group_id,
                })),
              ]}
              onChange={(value) => setFormValues({ ...formValues, admin_permission_group_id: value })}
              label={`Admin Permission Group ${validAdminEmail() ? '' : '(Disabled)'}`}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            {!user?.user_id && (
              <FormControlLabel
                control={
                  <Checkbox
                    // onChange={() => setSendInvitationEmail(!sendInvitationEmail)}
                    onChange={() => setSendInvitationEmail(true)}
                    value="send_invitation"
                    style={{ color: '#424E7A' }}
                  />
                }
                label="Send Invitation Email"
                style={{ marginTop: 5, color: '#424E7A', fontSize: '18px' }}
              />
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} md={8}>
          <div style={{ maxWidth: '92%' }}>
            <Grid container justifyContent="space-between">
              <Grid item>
                <PolarisLabel label="Machine Access" styleOverrides={{ lineHeight: '40px' }} />
              </Grid>
              {currentUserHasAdminPermission('ksi_superadmin') && (
                <Grid item>
                  <Button variant="text" onClick={() => null}>
                    Load All
                  </Button>
                </Grid>
              )}
            </Grid>
            <MachineAutocompleteInput
              label=""
              loading={userMachinesByCompanyLoading}
              pluralLabel="Machines"
              choices={combinedMachines}
              setValue={(value) => {
                setSelectedMachines([].concat(value));
              }}
              value={selectedMachines as string[]}
            />
          </div>
        </Grid>
        {!selectedCompanyOption?.label && (
          <p style={{ color: 'red' }}>Please select a company to see machine options.</p>
        )}
        <Grid item xs={12} md={8} style={{ marginTop: 15 }}>
          {currentUserHasAdminPermission('ksi_admin') &&
            (user?.user_id ? (
              <Button variant="contained" color="primary" onClick={handleUpdate} disabled={userSubmitting}>
                {userSubmitting ? 'Saving...' : 'Update User'}
              </Button>
            ) : (
              <Button variant="contained" color="primary" onClick={handleCreate} disabled={userSubmitting}>
                {userSubmitting ? 'Saving...' : 'Create User'}
              </Button>
            ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default UserForm;
