/* eslint-disable react-hooks/exhaustive-deps, react/jsx-props-no-spreading */
import React from 'react';
import { Button, Grid } from '@mui/material';
import FormInput from '../../../modules/shared/form/FormInput';
import SelectInput from '../../../modules/shared/form/SelectInput';
import { CustomerAutoBulkFormValues } from '../model';
import { CustomersAutoBulkContext } from '../contexts/CustomersAutoBulkContext';
import { CustomersAutoBulkValidationsContext } from '../contexts/CustomersAutoBulkValidationsContext';

interface CustomerAutoBulkFormProps {
  customer: any;
  machineSerial?: string;
  onSuccess?: () => void;
  onBack?: () => void;
}

const CustomerAutoBulkForm: React.FC<CustomerAutoBulkFormProps> = (props) => {
  const { customer, machineSerial, onSuccess, onBack } = props;

  const {
    updateCustomerAutoBulk,
    createCustomerAutoBulk,
    deleteCustomerAutoBulk,
    customerDeleting,
    customerSubmitting,
    activeMachine,
  } = React.useContext(CustomersAutoBulkContext);
  const { validateCustomersAutoBulkFields, formErrors } = React.useContext(CustomersAutoBulkValidationsContext);
  const [formValues, setFormValues] = React.useState<CustomerAutoBulkFormValues>({});
  const [actionButtonDisabled, setActionButtonDisabled] = React.useState(false);

  const handleUpdate = () => {
    updateCustomerAutoBulk(customer.customer_id, formValues);
  };

  const handleCreate = () => {
    createCustomerAutoBulk(formValues, onSuccess);
  };

  const handleDelete = () => {
    deleteCustomerAutoBulk(formValues.customer_id);
  };

  React.useEffect(() => {
    const abCustomersFieldsValid = validateCustomersAutoBulkFields(formValues);
    setActionButtonDisabled(!abCustomersFieldsValid);
  }, [formValues]);

  React.useEffect(() => {
    setFormValues({
      customer_id: customer?.customer_id,
      customer_name: customer?.customer_name,
      customer_contact_first: customer?.customer_contact_first,
      customer_contact_last: customer?.customer_contact_last,
      customer_address: customer?.customer_address,
      customer_city: customer?.customer_city,
      customer_state: customer?.customer_state,
      customer_zip: customer?.customer_zip,
      customer_phone_1: customer?.customer_phone_1,
      customer_email: customer?.customer_email,
      active: customer?.active || 'True',
    });
  }, [customer]);

  return (
    <Grid container>
      <Grid item xs={8}>
        <form noValidate autoComplete="off">
          <Grid container spacing={3}>
            <Grid item xs={5}>
              <FormInput
                value={formValues?.customer_name}
                onChange={(value) => setFormValues({ ...formValues, customer_name: value })}
                label="Customer Name"
                errorMessage={formErrors.CustomerName}
              />
            </Grid>
            <Grid item xs={5}>
              <FormInput
                value={formValues?.customer_contact_first}
                onChange={(value) => setFormValues({ ...formValues, customer_contact_first: value })}
                label="First Name"
                errorMessage={formErrors.CustomerContactFirst}
              />
            </Grid>
            <Grid item xs={5}>
              <FormInput
                value={formValues?.customer_contact_last}
                onChange={(value) => setFormValues({ ...formValues, customer_contact_last: value })}
                label="Last Name"
                errorMessage={formErrors.CustomerContactLast}
              />
            </Grid>
            <Grid item xs={5}>
              <FormInput
                value={formValues?.customer_address}
                onChange={(value) => setFormValues({ ...formValues, customer_address: value })}
                label="Address"
                errorMessage={formErrors.CustomerAddress}
              />
            </Grid>
            <Grid item xs={5}>
              <FormInput
                value={formValues?.customer_city}
                onChange={(value) => setFormValues({ ...formValues, customer_city: value })}
                label="City"
                errorMessage={formErrors.CustomerCity}
              />
            </Grid>
            <Grid item xs={5}>
              <FormInput
                value={formValues?.customer_state}
                onChange={(value) => setFormValues({ ...formValues, customer_state: value })}
                label="State"
                errorMessage={formErrors.CustomerState}
              />
            </Grid>
            <Grid item xs={5}>
              <FormInput
                value={formValues?.customer_zip}
                onChange={(value) => setFormValues({ ...formValues, customer_zip: value })}
                label="Zip"
                errorMessage={formErrors.CustomerZip}
              />
            </Grid>
            <Grid item xs={5}>
              <FormInput
                value={formValues?.customer_phone_1}
                onChange={(value) => setFormValues({ ...formValues, customer_phone_1: value })}
                label="Phone #"
                errorMessage={formErrors.CustomerPhone}
              />
            </Grid>
            <Grid item xs={5}>
              <FormInput
                value={formValues?.customer_email}
                onChange={(value) => setFormValues({ ...formValues, customer_email: value })}
                label="Email"
                errorMessage={formErrors.CustomerEmail}
              />
            </Grid>
            <Grid item xs={5}>
              <SelectInput
                value={formValues?.active}
                choices={[
                  { label: 'Yes', value: 'True' },
                  { label: 'No', value: 'False' },
                ]}
                onChange={(value) => setFormValues({ ...formValues, active: value })}
                label="Active"
              />
            </Grid>
          </Grid>
          <br />
          {activeMachine ? (
            <>
              {customer?.customer_id ? (
                <Button
                  variant="contained"
                  color="primary"
                  disabled={actionButtonDisabled || customerSubmitting || customerDeleting}
                  onClick={handleUpdate}
                >
                  {customerSubmitting ? 'Updating...' : 'Update'}
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  disabled={actionButtonDisabled || customerSubmitting}
                  onClick={handleCreate}
                >
                  {customerSubmitting ? 'Creating...' : 'Create'}
                </Button>
              )}
              <Button variant="contained" color="primary" onClick={onBack} style={{ marginLeft: 8 }}>
                Cancel
              </Button>
            </>
          ) : (
            <Button variant="contained" color="primary">
              Select A Machine
            </Button>
          )}
        </form>
      </Grid>
      {formValues?.customer_id && (
        <Grid item xs={4} style={{ display: 'flex', justifyContent: 'right' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleDelete}
            disabled={customerSubmitting || customerDeleting}
          >
            {customerDeleting ? 'Deleting...' : 'Delete'}
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export default CustomerAutoBulkForm;
