import React from 'react';
import axios from '../../../modules/utils/axios.utils';
import { AutoBulkTransaction } from '../model';

interface SourcesContextInterface {
  errorMessage?: string;
  exportTransactions?: (
    exportEmail: string,
    includeHeader: boolean,
    includeProducts: boolean,
    localTime: any,
    utcOffset: any,
    filterParams: string,
    filterType: string,
    yearlyOverview?: boolean,
    combineMachineTotals?: boolean,
  ) => Promise<void>;
  fetchSources?: (filterParams?: string) => Promise<void>;
  sourceMetrics?: Record<string, string>;
  sources?: AutoBulkTransaction[];
  sourcesLoading?: boolean;
}

const SourcesContext = React.createContext<SourcesContextInterface>({});

const SourcesContextConsumer = SourcesContext.Consumer;
const SourcesContextProvider: React.FC = ({ children }) => {
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [sourceMetrics, setSourceMetrics] = React.useState<Record<string, string>>({});
  const [sources, setSources] = React.useState<AutoBulkTransaction[]>([]);
  const [sourcesLoading, setSourcesLoading] = React.useState<boolean>(true);

  const fetchSources = async (filterParams = '') => {
    setSourcesLoading(true);
    setErrorMessage('');

    const cachedMachines = JSON.parse(localStorage.getItem('autoBulkMachines') || '[]');
    const cachedTimePeriod = localStorage.getItem('timePeriod') || '';
    const cachedStartDate = localStorage.getItem('startDate');
    const cachedEndDate = localStorage.getItem('endDate');

    let timeParams = {};

    if (cachedStartDate && cachedEndDate) {
      timeParams =
        cachedTimePeriod !== 'Custom'
          ? { time_period: cachedTimePeriod || '' }
          : {
              work_order_start_date_after: cachedStartDate || '',
              work_order_start_date_before: cachedEndDate || '',
            };
    } else {
      timeParams = { time_period: cachedTimePeriod || '' };
    }

    const params = new URLSearchParams({
      machine: cachedMachines.join(),
      ...Object.fromEntries(new URLSearchParams(filterParams)),
      ...timeParams,
    }).toString();

    axios
      .get<string, any>(`api/autobulk/analytics/transactions/sources/?${params}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
        },
      })
      .then((response) => {
        setSources(response.data.results);
        setSourceMetrics({
          total_records: response.data.count,
        });
        setSourcesLoading(false);
      })
      .catch((error) => {
        setErrorMessage(error?.response?.data?.Message);
        setSources([]);
        setSourcesLoading(false);
      });
  };

  const exportTransactions = async (
    exportEmail,
    includeHeader,
    includeProducts,
    localTime,
    utcOffset,
    filterParams,
    filterType,
    yearlyOverview,
    combineMachineTotals,
  ) => {
    setSourcesLoading(true);

    const cachedMachines = JSON.parse(localStorage.getItem('autoBulkMachines') || '[]');
    const cachedStartDate = localStorage.getItem('startDate');
    const cachedEndDate = localStorage.getItem('endDate');

    axios
      .post<any, any>(
        `api/autobulk/analytics/exports/sources`,
        {
          cachedEndDate,
          cachedMachines,
          cachedStartDate,
          exportEmail,
          includeHeader,
          includeProducts,
          localTime,
          utcOffset,
          filterParams,
          filterType,
          yearlyOverview,
          combineMachineTotals,
        },
        { headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` } },
      )
      .then((response) => {
        setSourcesLoading(false);
      })
      .catch((error) => {
        setSourcesLoading(false);
      });
  };

  return (
    <SourcesContext.Provider
      value={{
        errorMessage,
        exportTransactions,
        fetchSources,
        sourceMetrics,
        sources,
        sourcesLoading,
      }}
    >
      {children}
    </SourcesContext.Provider>
  );
};

export { SourcesContextProvider, SourcesContextConsumer, SourcesContext };
