import React from 'react';
import { Button, Grid } from '@mui/material';
import { SelectOption } from '../../shared/model';
import MachineAutocompleteFilter from '../../shared/form/MachineAutocompleteFilter';
import DateSelectFilter from '../../shared/form/DateSelectFilter';
import { MachineRequestFiltersContext } from '../contexts/MachineRequestFiltersContext';
import SelectInput from '../../shared/form/SelectInput';

export interface FilterProps {
  label: string;
  pluralLabel: string;
  selector: string;
  width: any;
  type: string;
  optionSelector: string;
  options?: SelectOption[];
}

interface MachineRequestFilterBarProps {
  filterValues?: any;
  setFilterValues?: any;
  filterOptions?: any;
  machineChoices?: any;
  machineChoicesLoading?: any;
  applyFilter?: (filters: string) => void;
}

const MachineRequestFilterBar: React.FC<MachineRequestFilterBarProps> = (props) => {
  const { filterValues, setFilterValues, applyFilter, filterOptions, machineChoices, machineChoicesLoading } = props;
  const [startDate, setStartDate] = React.useState<string>(sessionStorage.getItem('startDate') || '');
  const [endDate, setEndDate] = React.useState<string>(sessionStorage.getItem('endDate') || '');
  const [requestType, setRequestType] = React.useState<string>(sessionStorage.getItem('requestType') || 'All');
  const [requestCategory, setRequestCategory] = React.useState<string>(
    sessionStorage.getItem('requestCategory') || 'All',
  );
  const [requestStatus, setRequestStatus] = React.useState<string>(sessionStorage.getItem('requestStatus') || 'All');

  const { fetchMachineRequestFilterOptions, formatMachineRequestFilterValues } =
    React.useContext(MachineRequestFiltersContext);

  const [machines, setMachines] = React.useState<string[]>(JSON.parse(localStorage.getItem('commonMachines') || '[]'));

  console.log();
  React.useEffect(() => {
    localStorage.setItem('commonMachines', JSON.stringify(machines));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [machines]);

  React.useEffect(() => {
    sessionStorage.setItem('startDate', startDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate]);

  React.useEffect(() => {
    sessionStorage.setItem('endDate', endDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endDate]);

  React.useEffect(() => {
    sessionStorage.setItem('requestType', requestType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestType]);

  React.useEffect(() => {
    sessionStorage.setItem('requestCategory', requestCategory);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestCategory]);

  React.useEffect(() => {
    sessionStorage.setItem('requestStatus', requestStatus);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestStatus]);

  const handleApplyFilters = () => {
    const filterHeaders = formatMachineRequestFilterValues(
      startDate,
      endDate,
      machines,
      requestType,
      requestCategory,
      requestStatus,
    );
    setFilterValues(filterHeaders);
    applyFilter(filterHeaders);
  };

  React.useEffect(() => {
    handleApplyFilters();
  }, []);

  React.useEffect(() => {
    fetchMachineRequestFilterOptions();
  }, []);

  const handleFiltersKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      handleApplyFilters();
    }
  };

  return (
    <Grid container spacing={1} alignItems="flex-start" onKeyDown={handleFiltersKeyPress}>
      <Grid item xs={12}>
        <Grid container spacing={1} alignItems="flex-start">
          <Grid item>
            <div>
              <DateSelectFilter />
            </div>
          </Grid>
          <Grid item>
            <MachineAutocompleteFilter
              label="Machines"
              loading={machineChoicesLoading}
              pluralLabel="Machines"
              choices={machineChoices}
              setValue={(value) => {
                setMachines([].concat(value));
              }}
              value={machines as string[]}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <SelectInput
              value={requestType}
              choices={filterOptions?.request_type_choices}
              defaultChoice={{ label: 'All', value: 'All' }}
              onChange={(value) => setRequestType(value)}
              label="Request Type"
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <SelectInput
              value={requestCategory}
              choices={filterOptions?.request_category_choices}
              defaultChoice={{ label: 'All', value: 'All' }}
              onChange={(value) => setRequestCategory(value)}
              label="Request Category"
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <SelectInput
              value={requestStatus}
              choices={[
                { label: 'All', value: 'All' },
                { label: 'InProcess', value: 'InProcess' },
                { label: 'Completed', value: 'Completed' },
              ]}
              onChange={(value) => setRequestStatus(value)}
              label="Status"
            />
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              style={{ color: '#fff', marginTop: 19 }}
              disableElevation
              onClick={() => {
                handleApplyFilters();
              }}
            >
              Apply
            </Button>
            <span style={{ marginRight: 7 }} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MachineRequestFilterBar;
