import React, { Dispatch, SetStateAction } from 'react';
import axios from '../../../modules/utils/axios.utils';
import { WorkOrderAutoBulk, WorkOrderAutoBulkFormValues } from '../model';
import { MACHINE_MANAGEMENT_RETRY_INTERVAL, MACHINE_MANAGEMENT_TOTAL_RETRIES } from '../../../modules/utils/env';
import { SnackBarContext } from '../../../modules/snackBar/contexts/SnackBarContext';

interface WorkOrdersAutoBulkContextInterface {
  fetchCachedWorkOrdersAutoBulk?: (machineSerial: string) => Promise<void>;
  fetchCachedWorkOrderOptionsAutoBulk?: (machineSerial: string) => Promise<void>;

  fetchWorkOrderOptionsAutoBulk?: (machineSerial: string, onSuccess?: () => void) => Promise<void>;

  fetchWorkOrdersAutoBulk?: (
    machineSerial: string,
    genericFilter: string,
    statusFilter: string,
    completedFilter: string,
    apiLinkedFilter: string,
    onSuccess?: () => void,
  ) => Promise<void>;
  workOrdersRefreshedAt?: string;
  workOrders?: WorkOrderAutoBulk[];
  workOrderOptions?: any;
  workOrdersLoading?: boolean;

  createOrUpdateWorkOrderAutoBulk?: (
    machineSerial: string,
    formValues: WorkOrderAutoBulkFormValues,
    onSuccess?: () => void,
  ) => Promise<void>;
  workOrderSubmitting?: boolean;

  updateWorkOrderAutoBulk?: (
    machineSerial: string,
    formValues: WorkOrderAutoBulkFormValues,
    onSuccess?: () => void,
  ) => Promise<void>;

  deleteWorkOrderAutoBulk?: (workOrderId: string) => Promise<void>;
  workOrderDeleting?: boolean;

  creatingPage?: boolean;
  setCreatingPage?: Dispatch<SetStateAction<boolean>>;
  detailPage?: boolean;
  setDetailPage?: Dispatch<SetStateAction<boolean>>;

  setActiveMachine?: Dispatch<SetStateAction<Record<string, string>>>;
  activeMachine?: Record<string, string>;

  setActiveWorkOrderAutoBulk?: Dispatch<SetStateAction<Record<string, string>>>;
  activeWorkOrderAutoBulk?: Record<string, string>;

  handleGenericFilterChange?: (genericFilter: string) => Promise<void>;
  genericFilter?: string;

  handleStatusFilterChange?: (statusFilter: string) => Promise<void>;
  statusFilter?: string;

  handleCompleteFilterChange?: (statusFilter: string) => Promise<void>;
  completeFilter?: string;

  handleApiLinkedFilterChange?: (apiLinkedFilter: string) => Promise<void>;
  apiLinkedFilter?: string;
}

function createWorkOrderBody(values) {
  return {
    work_order_id: values.work_order_id,
    work_order_name: values.work_order_name,
    work_order_description: values.work_order_description,
    work_order_comment: values.work_order_comment,
    work_order_po_number: values.work_order_po_number,
    customer_id: values.customer_id,
    work_order_by_area: values.work_order_by_area,
    work_order_by_area_total: values.work_order_by_area_total,
    active: values.active,
    transport_id: values.transport_id,
    driver_id: values.driver_id,
    date_and_time_last_modified: values.date_and_time_last_modified,
    work_order_items: values.work_order_items.map((item) => ({
      product_id: item.product_id,
      product_target_amt: item.product_target_amt,
      product_target_uom: item.product_target_uom,
    })),
  };
}

const WorkOrdersAutoBulkContext = React.createContext<WorkOrdersAutoBulkContextInterface>({});

const WorkOrdersAutoBulkContextConsumer = WorkOrdersAutoBulkContext.Consumer;
const WorkOrdersAutoBulkContextProvider: React.FC = ({ children }) => {
  const { showSuccessSnackBar, showErrorSnackBar, showMachineManagementSnackBar } = React.useContext(SnackBarContext);

  const [workOrders, setWorkOrdersAutoBulk] = React.useState([]);
  const [workOrderOptions, setWorkOrderOptionsAutoBulk] = React.useState({});
  const [workOrdersLoading, setWorkOrdersAutoBulkLoading] = React.useState(true);
  const [workOrderSubmitting, setWorkOrderAutoBulkSubmitting] = React.useState(false);
  const [workOrdersRefreshedAt, setWorkOrdersAutoBulkRefreshedAt] = React.useState<string>();
  const [activeWorkOrderAutoBulk, setActiveWorkOrderAutoBulk] = React.useState({});
  const [workOrderDeleting, setWorkOrderAutoBulkDeleting] = React.useState(false);
  const [creatingPage, setCreatingPage] = React.useState<boolean>(false);
  const [detailPage, setDetailPage] = React.useState<boolean>(false);
  const [activeMachine, setActiveMachine] = React.useState<Record<string, string>>({
    label: localStorage.getItem('machineManagementAutoBulkMachine') || 'Select A Machine',
    value: localStorage.getItem('machineManagementAutoBulkMachine') || 'Select A Machine',
  });
  const [genericFilter, setGenericFilter] = React.useState<string>('');
  const [statusFilter, setStatusFilter] = React.useState<string>('All');
  const [completeFilter, setCompleteFilter] = React.useState<string>('False');
  const [apiLinkedFilter, setApiLinkedFilter] = React.useState<string>('All');

  React.useEffect(() => {
    if (activeMachine?.value === 'Select A Machine') {
      setWorkOrdersAutoBulkLoading(false);
    }
  }, [activeMachine]);

  const fetchCachedWorkOrdersAutoBulk = async (machineSerial: string) => {
    setWorkOrdersAutoBulkLoading(true);
    setWorkOrdersAutoBulkRefreshedAt(null);
    const retryCount = 0;

    axios
      .get<string, any>(`api/autobulk/onprem/work_orders/${machineSerial}/`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      })
      .then((response) => {
        setWorkOrdersAutoBulkLoading(false);
        setWorkOrdersAutoBulk(response.data.results);
        setWorkOrdersAutoBulkRefreshedAt(response.data.created_at);
      })
      .catch((error) => {
        setWorkOrdersAutoBulkLoading(false);
        setWorkOrdersAutoBulk([]);
        setWorkOrdersAutoBulkRefreshedAt(null);
      });
  };

  const fetchCachedWorkOrderOptionsAutoBulk = async (machineSerial: string) => {
    axios
      .get<string, any>(`api/autobulk/onprem/work_order_options/${machineSerial}/`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      })
      .then((response) => {
        setWorkOrderOptionsAutoBulk({
          ...workOrderOptions,
          customer_choices: response.data.results
            .filter((choice) => choice.table === 'customer')
            .map((choice) => ({
              label: choice.name,
              value: choice.id,
              firstName: choice.customer_contact_first,
              lastName: choice.customer_contact_last,
            })),
          product_choices: response.data.results
            .filter((choice) => choice.table === 'product')
            .map((choice) => ({
              label: choice.name,
              value: choice.id,
              product_type: choice.product_type,
              product_density: choice.product_density,
              product_density_type: choice.product_density_type,
            })),
          transport_choices: response.data.results
            .filter((choice) => choice.table === 'transport')
            .map((choice) => ({
              label: choice.name,
              value: choice.id,
              transport_number: choice.transport_number,
              rfid_assigned: choice.rfid_assigned,
              comment: choice.comment,
            })),
          driver_choices: response.data.results
            .filter((choice) => choice.table === 'driver')
            .map((choice) => ({
              label: choice.name,
              value: choice.id,
              transport_number: choice.transport_number,
              rfid_assigned: choice.rfid_assigned,
              comment: choice.comment,
            })),
        });
      })
      .catch((error) => {
        setWorkOrderOptionsAutoBulk([]);
      });
  };

  const fetchWorkOrdersAutoBulk = async (
    machineSerial: string,
    genericFilter: string,
    statusFilter: string,
    completeFilter: string,
    apiLinkedFilter: string,
    onSuccess?: () => void,
  ) => {
    setWorkOrdersAutoBulkLoading(true);
    setWorkOrdersAutoBulkRefreshedAt(null);
    let retryCount = 0;

    axios
      .get<string, any>(
        `api/autobulk/onprem/work_orders/?serial-number=${machineSerial}&generic-filter=${genericFilter}&status=${statusFilter}&completed=${completeFilter}&api-linked=${apiLinkedFilter}`,
        {
          headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
        },
      )
      .then((res) => {
        const fetchTimer = window.setInterval(() => {
          axios
            .get<string, any>(`api/autobulk/onprem/work_orders/${res.data.result}/`, {
              headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
            })
            .then((response) => {
              window.clearInterval(fetchTimer);
              setWorkOrdersAutoBulkLoading(false);
              setWorkOrdersAutoBulk(response.data.results);
              setWorkOrdersAutoBulkRefreshedAt(response.data.created_at);
            })
            .catch((error) => {
              retryCount += 1;

              if (retryCount > MACHINE_MANAGEMENT_TOTAL_RETRIES) {
                window.clearInterval(fetchTimer);
                setWorkOrdersAutoBulkLoading(false);
                setWorkOrdersAutoBulk([]);
                setWorkOrdersAutoBulkRefreshedAt(null);
              }
            });
        }, MACHINE_MANAGEMENT_RETRY_INTERVAL);
      });
  };

  const fetchWorkOrderOptionsAutoBulk = async (machineSerial: string, onSuccess?: () => void) => {
    let retryCount = 0;

    axios
      .get<string, any>(`api/autobulk/onprem/work_order_options/?serial-number=${machineSerial}`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      })
      .then((res) => {
        const fetchTimer = window.setInterval(() => {
          axios
            .get<string, any>(`api/autobulk/onprem/work_order_options/${res.data.result}/`, {
              headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
            })
            .then((response) => {
              window.clearInterval(fetchTimer);
              // setWorkOrderOptionsAutoBulk(response.data.results);
              setWorkOrderOptionsAutoBulk({
                ...workOrderOptions,

                customer_choices: response.data.results
                  .filter((choice) => choice.table === 'customer')
                  .map((choice) => ({
                    label: choice.name,
                    value: choice.id,
                    customer_contact_first: choice.customer_contact_first,
                    customer_contact_last: choice.customer_contact_last,
                  })),
                product_choices: response.data.results
                  .filter((choice) => choice.table === 'product')
                  .map((choice) => ({
                    label: choice.name,
                    value: choice.id,
                    product_type: choice.product_type,
                    product_density: choice.product_density,
                    product_density_type: choice.product_density_type,
                  })),
                transport_choices: response.data.results
                  .filter((choice) => choice.table === 'transport')
                  .map((choice) => ({
                    label: choice.name,
                    value: choice.id,
                    transport_number: choice.transport_number,
                    rfid_assigned: choice.rfid_assigned,
                    comment: choice.comment,
                  })),
                driver_choices: response.data.results
                  .filter((choice) => choice.table === 'driver')
                  .map((choice) => ({
                    label: choice.name,
                    value: choice.id,
                    transport_number: choice.transport_number,
                    rfid_assigned: choice.rfid_assigned,
                    comment: choice.comment,
                  })),
              });
            })
            .catch((error) => {
              retryCount += 1;

              if (retryCount > MACHINE_MANAGEMENT_TOTAL_RETRIES) {
                window.clearInterval(fetchTimer);
                setWorkOrderOptionsAutoBulk({});
              }
            });
        }, MACHINE_MANAGEMENT_RETRY_INTERVAL);
      });
  };

  const createOrUpdateWorkOrderAutoBulk = async (
    machineSerial: string,
    formValues: WorkOrderAutoBulkFormValues,
    onSuccess?: () => void,
  ) => {
    setWorkOrderAutoBulkSubmitting(true);
    showSuccessSnackBar('Sending record to the machine.');
    let retryCount = 0;

    axios
      .post<any, any>(
        `api/autobulk/onprem/work_orders/?serial-number=${machineSerial}`,
        createWorkOrderBody(formValues),
        { headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` } },
      )
      .then((res) => {
        const fetchTimer = window.setInterval(() => {
          axios
            .get<string, any>(`api/autobulk/onprem/work_orders/${res.data.result}/`, {
              headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
            })
            .then((response) => {
              window.clearInterval(fetchTimer);
              setWorkOrderAutoBulkSubmitting(false);
              showMachineManagementSnackBar(response.data.results[0]);
              fetchWorkOrderOptionsAutoBulk(activeMachine?.value);
              fetchWorkOrdersAutoBulk(
                activeMachine?.value,
                genericFilter,
                statusFilter,
                completeFilter,
                apiLinkedFilter,
                () => {
                  setWorkOrdersAutoBulkLoading(false);
                  setWorkOrderAutoBulkSubmitting(false);
                  onSuccess();
                },
              );
            })
            .catch((error) => {
              retryCount += 1;

              if (retryCount > MACHINE_MANAGEMENT_TOTAL_RETRIES) {
                window.clearInterval(fetchTimer);
                setWorkOrderAutoBulkSubmitting(false);
                showErrorSnackBar('No response from machine');
              }
            });
        }, MACHINE_MANAGEMENT_RETRY_INTERVAL);
      });
  };

  const deleteWorkOrderAutoBulk = async (workOrderId: string) => {
    setWorkOrderAutoBulkDeleting(true);
    showSuccessSnackBar('Sending update to the machine.');
    let retryCount = 0;

    axios
      .delete<any, any>(`api/autobulk/onprem/work_orders/${workOrderId}/?serial-number=${activeMachine?.value}`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      })
      .then((res) => {
        const timer = window.setInterval(() => {
          axios
            .get<string, any>(`api/autobulk/onprem/work_orders/${res.data.result}/`, {
              headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
            })
            .then((response) => {
              window.clearInterval(timer);
              setWorkOrdersAutoBulkLoading(false);
              showMachineManagementSnackBar(response.data.results[0]);
              if (response.data.results[0].status === 'Success') {
                setDetailPage(false);
              }
              setWorkOrderAutoBulkDeleting(false);
              fetchWorkOrdersAutoBulk(activeMachine?.value, '', '', '', '', () => {});
            })
            .catch((error) => {
              retryCount += 1;

              if (retryCount > MACHINE_MANAGEMENT_TOTAL_RETRIES) {
                window.clearInterval(timer);
                showErrorSnackBar('No response from machine');
                setWorkOrdersAutoBulkLoading(false);
                setWorkOrderAutoBulkDeleting(false);
              }
            });
        }, MACHINE_MANAGEMENT_RETRY_INTERVAL);
      });
  };

  const handleGenericFilterChange = async (genericFilter: string) => {
    setGenericFilter(genericFilter);
  };

  const handleStatusFilterChange = async (statusFilter: string) => {
    setStatusFilter(statusFilter);
  };

  const handleCompleteFilterChange = async (completeFilter: string) => {
    setCompleteFilter(completeFilter);
  };

  const handleApiLinkedFilterChange = async (apiLinkedFilter: string) => {
    setApiLinkedFilter(apiLinkedFilter);
  };

  return (
    <WorkOrdersAutoBulkContext.Provider
      value={{
        fetchCachedWorkOrdersAutoBulk,
        fetchCachedWorkOrderOptionsAutoBulk,
        fetchWorkOrderOptionsAutoBulk,

        fetchWorkOrdersAutoBulk,
        workOrders,
        workOrderOptions,
        workOrdersRefreshedAt,
        workOrdersLoading,

        createOrUpdateWorkOrderAutoBulk,
        workOrderSubmitting,
        deleteWorkOrderAutoBulk,
        workOrderDeleting,
        // updateWorkOrderAutoBulk,

        // workOrder,
        // workOrderLoading,

        creatingPage,
        setCreatingPage,
        detailPage,
        setDetailPage,

        setActiveWorkOrderAutoBulk,
        activeWorkOrderAutoBulk,

        setActiveMachine,
        activeMachine,

        handleGenericFilterChange,
        genericFilter,
        handleStatusFilterChange,
        statusFilter,
        handleCompleteFilterChange,
        completeFilter,
        handleApiLinkedFilterChange,
        apiLinkedFilter,
      }}
    >
      {children}
    </WorkOrdersAutoBulkContext.Provider>
  );
};

export { WorkOrdersAutoBulkContextProvider, WorkOrdersAutoBulkContextConsumer, WorkOrdersAutoBulkContext };
