import React, { Dispatch, SetStateAction } from 'react';
import { useHistory } from 'react-router-dom';
import axios from '../../../modules/utils/axios.utils';
import { CustomerAutoBulkFormValues } from '../model';
import { MACHINE_MANAGEMENT_RETRY_INTERVAL, MACHINE_MANAGEMENT_TOTAL_RETRIES } from '../../../modules/utils/env';
import { SnackBarContext } from '../../../modules/snackBar/contexts/SnackBarContext';

interface CustomersAutoBulkContextInterface {
  fetchCachedCustomersAutoBulk?: (machineSerial: string) => Promise<void>;
  fetchCustomersAutoBulk?: (
    machineSerial: string,
    genericFilter: string,
    statusFilter: string,
    apiLinkedFilter: string,
    onSuccess?: () => void,
  ) => Promise<void>;
  customersRefreshedAt?: string;
  customers?: any[];
  customersLoading?: boolean;

  createCustomerAutoBulk?: (formValues: CustomerAutoBulkFormValues, onSuccess: () => void) => Promise<void>;

  updateCustomerAutoBulk?: (userId: string, formValues: CustomerAutoBulkFormValues) => Promise<void>;

  deleteCustomerAutoBulk?: (customerId: string) => Promise<void>;
  customerDeleting?: boolean;

  creatingPage?: boolean;
  setCreatingPage?: Dispatch<SetStateAction<boolean>>;
  detailPage?: boolean;
  setDetailPage?: Dispatch<SetStateAction<boolean>>;

  customerSubmitting?: boolean;

  setActiveMachine?: Dispatch<SetStateAction<Record<string, string>>>;
  activeMachine?: Record<string, string>;

  setActiveCustomerAutoBulk?: Dispatch<SetStateAction<Record<string, string>>>;
  activeCustomerAutoBulk?: Record<string, string>;

  handleGenericFilterChange?: (genericFilter: string) => Promise<void>;
  genericFilter?: string;

  handleStatusFilterChange?: (statusFilter: string) => Promise<void>;
  statusFilter?: string;

  handleApiLinkedFilterChange?: (apiLinkedFilter: string) => Promise<void>;
  apiLinkedFilter?: string;
}

const CustomersAutoBulkContext = React.createContext<CustomersAutoBulkContextInterface>({});

const CustomersAutoBulkContextConsumer = CustomersAutoBulkContext.Consumer;
const CustomersAutoBulkContextProvider: React.FC = ({ children }) => {
  const history = useHistory();
  const { showErrorSnackBar, showSuccessSnackBar, showMachineManagementSnackBar } = React.useContext(SnackBarContext);

  const [customers, setCustomersAutoBulk] = React.useState([]);
  const [customersLoading, setCustomersAutoBulkLoading] = React.useState(true);
  const [customersRefreshedAt, setCustomersAutoBulkRefreshedAt] = React.useState<string>();
  const [activeCustomerAutoBulk, setActiveCustomerAutoBulk] = React.useState({});
  const [customerLoading, setCustomerAutoBulkLoading] = React.useState(true);
  const [customerSubmitting, setCustomerAutoBulkSubmitting] = React.useState(false);
  const [customerDeleting, setCustomerAutoBulkDeleting] = React.useState(false);
  const [creatingPage, setCreatingPage] = React.useState<boolean>(false);
  const [detailPage, setDetailPage] = React.useState<boolean>(false);
  const [activeMachine, setActiveMachine] = React.useState<Record<string, string>>({
    label: localStorage.getItem('machineManagementAutoBulkMachine') || 'Select A Machine',
    value: localStorage.getItem('machineManagementAutoBulkMachine') || 'Select A Machine',
  });
  const [genericFilter, setGenericFilter] = React.useState<string>('');
  const [statusFilter, setStatusFilter] = React.useState<string>('All');
  const [apiLinkedFilter, setApiLinkedFilter] = React.useState<string>('All');

  React.useEffect(() => {
    if (activeMachine?.value === 'Select A Machine') {
      setCustomersAutoBulkLoading(false);
    }
  }, [activeMachine]);

  const fetchCachedCustomersAutoBulk = async (machineSerial: string) => {
    setCustomersAutoBulkLoading(true);
    setCustomersAutoBulkRefreshedAt(null);
    const retryCount = 0;

    axios
      .get<string, any>(`api/autobulk/onprem/customers/${machineSerial}/`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      })
      .then((response) => {
        setCustomersAutoBulkLoading(false);
        setCustomersAutoBulk(response.data.results);
        setCustomersAutoBulkRefreshedAt(response.data.created_at);
      })
      .catch((error) => {
        setCustomersAutoBulkLoading(false);
        setCustomerAutoBulkSubmitting(false);
        setCustomersAutoBulk([]);
        setCustomersAutoBulkRefreshedAt(null);
      });
  };

  const fetchCustomersAutoBulk = async (
    machineSerial: string,
    genericFilter: string,
    statusFilter: string,
    apiLinkedFilter: string,
    onSuccess?: () => void,
  ) => {
    setCustomersAutoBulkLoading(true);
    setCustomersAutoBulkRefreshedAt(null);
    let retryCount = 0;

    axios
      .get<string, any>(
        `api/autobulk/onprem/customers/?serial-number=${machineSerial}&generic-filter=${genericFilter}&status=${statusFilter}&api-linked=${apiLinkedFilter}`,
        {
          headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
        },
      )
      .then((res) => {
        const fetchTimer = window.setInterval(() => {
          axios
            .get<string, any>(`api/autobulk/onprem/customers/${res.data.result}/`, {
              headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
            })
            .then((response) => {
              window.clearInterval(fetchTimer);
              setCustomersAutoBulkLoading(false);
              setCustomersAutoBulk(response.data.results);
              setCustomersAutoBulkRefreshedAt(response.data.created_at);
              onSuccess();
            })
            .catch((error) => {
              retryCount += 1;

              if (retryCount > MACHINE_MANAGEMENT_TOTAL_RETRIES) {
                window.clearInterval(fetchTimer);
                setCustomersAutoBulkLoading(false);
                setCustomerAutoBulkSubmitting(false);
                showErrorSnackBar('Unable to load customers');
                setCustomersAutoBulk([]);
                setCustomersAutoBulkRefreshedAt(null);
              }
            });
        }, MACHINE_MANAGEMENT_RETRY_INTERVAL);
      });
  };

  const updateCustomerAutoBulk = async (customerId: string, formValues: CustomerAutoBulkFormValues) => {
    setDetailPage(true);
    setCustomerAutoBulkSubmitting(true);
    setCustomersAutoBulkLoading(true);
    showSuccessSnackBar('Sending update to the machine.');
    let retryCount = 0;

    axios
      .put<any, any>(
        `api/autobulk/onprem/customers/0/?serial-number=${activeMachine?.value}`,
        { ...formValues },
        { headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` } },
      )
      .then((res) => {
        const updateTimer = window.setInterval(() => {
          axios
            .get<string, any>(`api/autobulk/onprem/customers/${res.data.result}`, {
              headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
            })
            .then((response) => {
              window.clearInterval(updateTimer);
              setCustomerAutoBulkLoading(false);
              showMachineManagementSnackBar(response.data.results[0]);
              fetchCustomersAutoBulk(activeMachine?.value, '', '', '', () => {
                setCustomerAutoBulkLoading(false);
                setCustomerAutoBulkSubmitting(false);
              });
            })
            .catch((error) => {
              retryCount += 1;

              if (retryCount > MACHINE_MANAGEMENT_TOTAL_RETRIES) {
                window.clearInterval(updateTimer);
                setCustomersAutoBulkLoading(false);
                setCustomerAutoBulkSubmitting(false);
                showErrorSnackBar('No response from machine');
                setCustomersAutoBulk([]);
                setCustomersAutoBulkRefreshedAt(null);
              }
            });
        }, MACHINE_MANAGEMENT_RETRY_INTERVAL);
      });
  };

  const createCustomerAutoBulk = async (formValues: CustomerAutoBulkFormValues, onSuccess?: () => void) => {
    setCustomerAutoBulkSubmitting(true);
    setCreatingPage(true);
    setCustomersAutoBulkLoading(true);
    showSuccessSnackBar('Sending record to the machine.');
    let retryCount = 0;

    axios
      .post<any, any>(
        `api/autobulk/onprem/customers/?serial-number=${activeMachine?.value}`,
        { ...formValues },
        { headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` } },
      )
      .then((res) => {
        const createTimer = window.setInterval(() => {
          axios
            .get<string, any>(`api/autobulk/onprem/customers/${res.data.result}/`, {
              headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
            })
            .then((response) => {
              window.clearInterval(createTimer);
              setCustomerAutoBulkLoading(false);
              showMachineManagementSnackBar(response.data.results[0]);
              fetchCustomersAutoBulk(activeMachine?.value, '', '', '', () => {
                setCustomerAutoBulkLoading(false);
                setCustomerAutoBulkSubmitting(false);
              });
            })
            .catch((error) => {
              retryCount += 1;

              if (retryCount > MACHINE_MANAGEMENT_TOTAL_RETRIES) {
                window.clearInterval(createTimer);
                setCustomersAutoBulkLoading(false);
                setCustomerAutoBulkSubmitting(false);
                showErrorSnackBar('No response from machine');
                setCustomersAutoBulk([]);
                setCustomersAutoBulkRefreshedAt(null);
              }
            });
        }, MACHINE_MANAGEMENT_RETRY_INTERVAL);
      });
  };

  const deleteCustomerAutoBulk = async (customerId: string) => {
    setCustomerAutoBulkDeleting(true);
    showSuccessSnackBar('Sending update to the machine.');
    let retryCount = 0;

    axios
      .delete<any, any>(`api/autobulk/onprem/customers/${customerId}/?serial-number=${activeMachine?.value}`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      })
      .then((res) => {
        const timer = window.setInterval(() => {
          axios
            .get<string, any>(`api/autobulk/onprem/customers/${res.data.result}/`, {
              headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
            })
            .then((response) => {
              window.clearInterval(timer);
              setCustomerAutoBulkLoading(false);
              showMachineManagementSnackBar(response.data.results[0]);
              if (response.data.results[0].status === 'Success') {
                setDetailPage(false);
              }
              setCustomerAutoBulkDeleting(false);
              fetchCustomersAutoBulk(activeMachine?.value, '', '', '', () => {});
            })
            .catch((error) => {
              retryCount += 1;

              if (retryCount > MACHINE_MANAGEMENT_TOTAL_RETRIES) {
                window.clearInterval(timer);
                showErrorSnackBar('No response from machine');
                setCustomerAutoBulkLoading(false);
                setCustomerAutoBulkDeleting(false);
              }
            });
        }, MACHINE_MANAGEMENT_RETRY_INTERVAL);
      });
  };

  const handleGenericFilterChange = async (genericFilter: string) => {
    setGenericFilter(genericFilter);
  };

  const handleStatusFilterChange = async (statusFilter: string) => {
    setStatusFilter(statusFilter);
  };

  const handleApiLinkedFilterChange = async (apiLinkedFilter: string) => {
    setApiLinkedFilter(apiLinkedFilter);
  };

  return (
    <CustomersAutoBulkContext.Provider
      value={{
        fetchCachedCustomersAutoBulk,
        fetchCustomersAutoBulk,
        customers,
        customersRefreshedAt,
        customersLoading,

        createCustomerAutoBulk,
        updateCustomerAutoBulk,
        deleteCustomerAutoBulk,
        customerDeleting,
        customerSubmitting,

        creatingPage,
        setCreatingPage,
        detailPage,
        setDetailPage,

        setActiveCustomerAutoBulk,
        activeCustomerAutoBulk,

        setActiveMachine,
        activeMachine,

        handleGenericFilterChange,
        genericFilter,
        handleStatusFilterChange,
        statusFilter,
        handleApiLinkedFilterChange,
        apiLinkedFilter,
      }}
    >
      {children}
    </CustomersAutoBulkContext.Provider>
  );
};

export { CustomersAutoBulkContextProvider, CustomersAutoBulkContextConsumer, CustomersAutoBulkContext };
