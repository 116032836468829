import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Card } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import DataTable from '../../dataTable/DataTable/DataTable';
import locationColumns from '../constants/locationColumns';
import { UsersContext } from '../../userUsers/contexts/UsersContext';

interface UserCompanyUsersProps {
  company: any;
}

const UserCompanyUsers: React.FC<UserCompanyUsersProps> = (props) => {
  const { company } = props;
  const history = useHistory();
  const { fetchUsers, users, usersLoading } = React.useContext(UsersContext);

  React.useEffect(() => {
    fetchUsers();
  }, []);

  const columns = [
    {
      name: 'Full Name',
      selector: 'name',
      sortable: true,
    },
    {
      name: 'Email',
      selector: 'email_address',
      sortable: true,
    },
    {
      name: 'Actions',
      selector: 'status',
      sortable: true,
      grow: 0.5,
      cell: (row): React.ReactNode => (
        <div>
          <a href={`/account_management/users/${row.user_id}`} target="_blank" rel="noreferrer">
            Edit
          </a>
        </div>
      ),
    },
  ];

  return (
    <Card>
      <CardContent>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="h2" component="h2">
              Company Users
            </Typography>
          </Grid>
        </Grid>
        <DataTable
          noHeader
          columns={columns}
          data={users?.filter((user) => user?.status)}
          onRowClicked={(row) => history.push(`/account_management/users/${row.user_id}`)}
          defaultSortAsc={false}
          striped
          highlightOnHover
          progressPending={usersLoading}
          pointerOnHover
          pagination
          paginationPerPage={10}
          paginationRowsPerPageOptions={[10]}
        />
      </CardContent>
    </Card>
  );
};

export default UserCompanyUsers;
