import { IDataTableColumn } from '../../dataTable/DataTable/model';

const columns: IDataTableColumn<any>[] = [
  {
    name: 'Serial Number',
    selector: (row) => row.serial_number,
    sortable: true,
  },
  {
    name: 'Requested Motherboard SN',
    selector: (row) => row.req_motherboard_serial_number,
    sortable: true,
  },
  {
    name: 'Current Motherboard SN',
    selector: (row) => row.curr_motherboard_serial_number,
    sortable: true,
  },
  {
    name: 'Certificate State',
    selector: (row) => row.certificate_state,
    sortable: true,
  },
  {
    name: 'Request Date',
    selector: (row) => row.cert_request_date,
    sortable: true,
    format: (row) => new Date(row.cert_request_date).toLocaleDateString(),
  },
  {
    name: 'Approval Date',
    selector: (row) => row.approval_date,
    sortable: true,
    format: (row) => (row.approval_date ? new Date(row.approval_date).toLocaleDateString() : 'N/A'),
  },
];

export default columns;
