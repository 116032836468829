import React from 'react';
import { Button, Grid } from '@mui/material';
import { SelectOption } from '../../shared/model';
import FormInput from '../../shared/form/FormInput';
import SelectInput from '../../shared/form/SelectInput';

export interface FilterProps {
  label: string;
  pluralLabel: string;
  selector: string;
  width: any;
  type: string;
  optionSelector: string;
  options?: SelectOption[];
}

interface MachineRequestRecordsFilterBarProps {
  setFilterValues?: any;
  filterValues?: any;
  applyFilterValues?: any;
}

const MachineRequestRecordsFilterBar: React.FC<MachineRequestRecordsFilterBarProps> = (props) => {
  const { setFilterValues, filterValues, applyFilterValues } = props;

  return (
    <Grid container spacing={1} alignItems="flex-start">
      <Grid item xs={12}>
        <Grid container spacing={1} alignItems="flex-start">
          <Grid item xs={12} md={2}>
            <FormInput
              value={filterValues?.record_info}
              onChange={(value) => {
                setFilterValues({ ...filterValues, record_info: value });
              }}
              label="Record Info"
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <FormInput
              value={filterValues?.response_message}
              onChange={(value) => {
                setFilterValues({ ...filterValues, response_message: value });
              }}
              label="Response Message"
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <SelectInput
              value={filterValues.response_status}
              choices={[
                { label: 'Pending', value: 'Pending' },
                { label: 'Success', value: 'Success' },
                { label: 'Error', value: 'Error' },
                { label: 'Failed', value: 'Failed' },
              ]}
              defaultChoice={{ label: 'All', value: 'All' }}
              onChange={(value) => setFilterValues({ ...filterValues, response_status: value })}
              label="Status"
            />
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              style={{ color: '#fff', marginTop: 19 }}
              disableElevation
              onClick={() => {
                applyFilterValues();
              }}
            >
              Apply
            </Button>
            <span style={{ marginRight: 7 }} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MachineRequestRecordsFilterBar;
