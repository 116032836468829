/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Card, CardContent, Typography, Grid, Skeleton } from '@mui/material';
import DataTable from '../../../modules/dataTable/DataTable/DataTable';
import AutoTreatTransactionFilterBar from '../../../modules/shared/form/AutoTreatTransactionFilterBar';
import transactionColumns from '../constants/treatmentColumns';
import { TreatmentProductsContext } from '../contexts/TreatmentProductsContext';
import { MachinesContext } from '../../../modules/machinesAdminManagement/contexts/MachinesContext';
import { AutoTreatFiltersContext } from '../contexts/AutoTreatFiltersContext';
import filters from '../constants/treatmentFilters';

const AutoTreatTreatmentProductSummary: React.FunctionComponent = () => {
  const {
    errorMessage,
    fetchPaginatedTreatmentProducts,
    fetchTreatmentProducts,
    treatmentProductMetrics,
    treatmentProducts,
    treatmentProductsLoading,
    exportTransactions,
    includeProducts,
    setIncludeProducts,
  } = React.useContext(TreatmentProductsContext);

  const { fetchAutoTreatMachines, autoTreatMachines } = React.useContext(MachinesContext);
  const { filterOptions, fetchFilterOptions } = React.useContext(AutoTreatFiltersContext);

  const [activePage, setActivePage] = React.useState(1);
  const [combineMachineTotals, setCombineMachineTotals] = React.useState(false);
  const [filterType] = React.useState('Treatment Products');
  const [filterValues, setFilterValues] = React.useState<Record<string, string | string[]>>({});
  const [yearlyOverview, setYearlyOverview] = React.useState<boolean>(true);
  const [timePeriod, setTimePeriod] = React.useState<string>('');

  React.useEffect(() => {
    fetchFilterOptions();
    fetchTreatmentProducts(combineMachineTotals, '');
    fetchAutoTreatMachines();
  }, []);

  React.useEffect(() => {
    setTimePeriod(sessionStorage.getItem('timePeriod'));
  }, [treatmentProducts, treatmentProductMetrics]);

  const handlePageChange = (page: number) => {
    if (page > activePage) {
      setActivePage(page);
      fetchPaginatedTreatmentProducts(treatmentProductMetrics.next_page);
    } else {
      setActivePage(page);
      fetchPaginatedTreatmentProducts(treatmentProductMetrics.previous_page);
    }
  };

  const handleFilter = (filterParams: string, combined: boolean) => {
    fetchTreatmentProducts(combined, filterParams);
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item>
          <Typography variant="h1" component="h1">
            AutoTreat Treatment Product Summary
          </Typography>
          <br />
        </Grid>
      </Grid>
      <br />
      <Grid justifyContent="space-between" container>
        <Grid item md={12}>
          <AutoTreatTransactionFilterBar
            applyFilter={handleFilter}
            combineMachineTotals={combineMachineTotals}
            filterChoices={filterOptions}
            filters={filters as any}
            filterType={filterType}
            filterValues={filterValues}
            setFilterValues={setFilterValues}
            localStorageMachineKey="autoTreatMachines"
            setCombineMachineTotals={setCombineMachineTotals}
            showCombineCheckbox
            exportTransactions={exportTransactions}
            includeProducts={includeProducts}
            setIncludeProducts={setIncludeProducts}
            transactionMetrics={null}
            treatmentProductMetrics={treatmentProductMetrics}
          />
          <br />
          <p style={{ color: 'red' }}>{errorMessage}</p>
        </Grid>
      </Grid>
      {treatmentProductsLoading ? (
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={3}>
            <Skeleton variant="rectangular" width="100%" height={118} />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <Skeleton variant="rectangular" width="100%" height={118} />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <Skeleton variant="rectangular" width="100%" height={118} />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <Skeleton variant="rectangular" width="100%" height={118} />
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={3}>
            <Card>
              <CardContent>
                <Grid container justifyContent="space-between">
                  <Grid item>
                    <p style={{ marginTop: 7 }}>Target Amount Total</p>
                    <p style={{ opacity: 0.6 }}>{timePeriod}</p>
                  </Grid>
                  <Grid item>
                    <span style={{ fontSize: 36, fontWeight: 700 }}>
                      {treatmentProductMetrics?.target_amt_total_with_uom}
                    </span>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <Card>
              <CardContent>
                <Grid container justifyContent="space-between">
                  <Grid item>
                    <p style={{ marginTop: 7 }}>Actual Amount Total</p>
                    <p style={{ opacity: 0.6 }}>{timePeriod}</p>
                  </Grid>
                  <Grid item>
                    <span style={{ fontSize: 36, fontWeight: 700 }}>
                      {treatmentProductMetrics?.actual_amt_total_with_uom}
                    </span>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <Card>
              <CardContent>
                <Grid container justifyContent="space-between">
                  <Grid item>
                    <p style={{ marginTop: 7 }}>Average Treatment Accuracy</p>
                    <p style={{ opacity: 0.6 }}>{timePeriod}</p>
                  </Grid>
                  <Grid item>
                    <span style={{ fontSize: 36, fontWeight: 700 }}>
                      {`${treatmentProductMetrics?.accuracy_with_uom}%`}
                    </span>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <Card>
              <CardContent>
                <Grid container justifyContent="space-between">
                  <Grid item xs={6}>
                    <p style={{ marginTop: 7 }}>Total Seed Units</p>
                    <p style={{ opacity: 0.6 }}>{timePeriod}</p>
                  </Grid>
                  <Grid item>
                    <span style={{ fontSize: 36, fontWeight: 700 }}>
                      {`${treatmentProductMetrics?.seed_units_total_with_uom}`}
                    </span>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      )}
      <br />
      <Card>
        <CardContent>
          <DataTable
            columns={transactionColumns(combineMachineTotals)}
            data={treatmentProducts}
            defaultSortAsc={false}
            defaultSortField="order_complete_date_and_time_utc"
            highlightOnHover
            noHeader
            pagination
            paginationPerPage={10}
            paginationRowsPerPageOptions={[10]}
            pointerOnHover
            progressPending={treatmentProductsLoading}
            striped
          />
        </CardContent>
        <br />
      </Card>
    </>
  );
};

export default AutoTreatTreatmentProductSummary;
