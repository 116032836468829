/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Card, CardContent, Typography, Grid } from '@mui/material';
import DataTable from '../../../modules/dataTable/DataTable/DataTable';
import AutoTreatTransactionFilterBar from '../../../modules/shared/form/AutoTreatTransactionFilterBar';
import seedVarietyColumns from '../constants/seedVarietyColumns';
import { SeedVarietiesContext } from '../contexts/SeedVarietiesContext';
import { MachinesContext } from '../../../modules/machinesAdminManagement/contexts/MachinesContext';
import { AutoTreatFiltersContext } from '../contexts/AutoTreatFiltersContext';
import filters from '../constants/seedVarietyFilters';

const SeedVarietySummary: React.FunctionComponent = () => {
  const {
    errorMessage,
    fetchSeedVarieties,
    fetchPaginatedSeedVarieties,
    seedVarieties,
    seedVarietiesLoading,
    seedVarietyMetrics,
    exportTransactions,
  } = React.useContext(SeedVarietiesContext);
  const { filterOptions, fetchFilterOptions } = React.useContext(AutoTreatFiltersContext);
  const { fetchAutoTreatMachines, autoTreatMachines } = React.useContext(MachinesContext);

  const [combineMachineTotals, setCombineMachineTotals] = React.useState(false);
  const [filterValues, setFilterValues] = React.useState<Record<string, string | string[]>>({});

  React.useEffect(() => {
    fetchFilterOptions();
    fetchSeedVarieties(combineMachineTotals, '');
    fetchAutoTreatMachines();
  }, []);

  const handleFilter = (filterParams: string, combined: boolean) => {
    fetchSeedVarieties(combined, filterParams);
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item>
          <Typography variant="h1" component="h1">
            Extended Seed Variety Summary
          </Typography>
          <br />
        </Grid>
      </Grid>
      <br />
      <Grid justifyContent="space-between" container>
        <Grid item md={12}>
          <AutoTreatTransactionFilterBar
            applyFilter={handleFilter}
            combineMachineTotals={combineMachineTotals}
            filterChoices={filterOptions}
            filters={filters as any}
            filterType="Seed Variety Summary"
            filterValues={filterValues}
            setFilterValues={setFilterValues}
            setCombineMachineTotals={setCombineMachineTotals}
            showCombineCheckbox
            exportTransactions={exportTransactions}
            includeProducts={null}
            setIncludeProducts={null}
            transactionMetrics={null}
            treatmentProductMetrics={null}
          />
          <br />
          <p style={{ color: 'red' }}>{errorMessage}</p>
        </Grid>
      </Grid>
      <br />
      <Card>
        <CardContent>
          <DataTable
            columns={seedVarietyColumns(combineMachineTotals)}
            data={seedVarieties}
            defaultSortAsc={false}
            defaultSortField="order_complete_date_and_time_utc"
            highlightOnHover
            noHeader
            pagination
            paginationPerPage={10}
            paginationRowsPerPageOptions={[10]}
            pointerOnHover
            progressPending={seedVarietiesLoading}
            striped
          />
        </CardContent>
        <br />
      </Card>
    </>
  );
};

export default SeedVarietySummary;
