/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Card, CardContent, Typography, Grid } from '@mui/material';
import DataTable from '../../../modules/dataTable/DataTable/DataTable';
import { columns, nestedColumns } from '../constants/columns';
import { WorkOrdersAutoBulkContext } from '../contexts/WorkOrdersAutoBulkContext';
import { WorkOrderAutoBulk } from '../model';
import { MachinesContext } from '../../../modules/machinesAdminManagement/contexts/MachinesContext';
import WorkOrderAutoBulkFilterBar from '../components/WorkOrdersAutoBulkFilterBar';

const WorkOrdersAutoBulkList: React.FunctionComponent = () => {
  const [initialDataLoaded, setDataLoaded] = React.useState(false);
  const [tableFilters, setTableFilters] = React.useState({ name: '', description: '', po_number: '', customer: '' });
  const {
    workOrders,
    setCreatingPage,
    setDetailPage,
    setActiveWorkOrderAutoBulk,
    activeWorkOrderAutoBulk,
    activeMachine,
    setActiveMachine,
    fetchCachedWorkOrdersAutoBulk,
    fetchCachedWorkOrderOptionsAutoBulk,
    fetchWorkOrdersAutoBulk,
    fetchWorkOrderOptionsAutoBulk,
  } = React.useContext(WorkOrdersAutoBulkContext);
  const [fitlerBarActive, setFitlerBarActive] = React.useState(true);
  const { fetchAutoBulkMachines, autoBulkMachines, machinesLoading } = React.useContext(MachinesContext);

  React.useEffect(() => {
    const workOrderAutoBulkMachine = localStorage.getItem('machineManagementAutoBulkMachine');
    if (workOrders.length === 0 && workOrderAutoBulkMachine) {
      fetchCachedWorkOrdersAutoBulk(workOrderAutoBulkMachine);
      fetchCachedWorkOrderOptionsAutoBulk(workOrderAutoBulkMachine);
    }
  }, []);

  React.useEffect(() => {
    const getMachines = async () => {
      if (!initialDataLoaded) {
        setDataLoaded(true);

        fetchAutoBulkMachines();
      }
    };

    getMachines();
  }, [initialDataLoaded, setDataLoaded, fetchWorkOrdersAutoBulk]);

  const filterCustomers = () => {
    if (tableFilters.name || tableFilters.description || tableFilters.po_number || tableFilters.customer) {
      const nameFilteredCustomers = workOrders.filter((workOrder) =>
        workOrder.work_order_name?.toLowerCase().includes(tableFilters.name?.toLowerCase()),
      );

      const descriptionFilteredCustomers = nameFilteredCustomers.filter(
        (workOrder) =>
          workOrder.work_order_description?.toLowerCase().includes(tableFilters.description?.toLowerCase()) ||
          workOrder.work_order_comment?.toLowerCase().includes(tableFilters.description?.toLowerCase()),
      );

      const poNumberFilteredCustomers = descriptionFilteredCustomers.filter((workOrder) =>
        workOrder.work_order_po_number?.toLowerCase().includes(tableFilters.po_number?.toLowerCase()),
      );

      const customerFilteredCustomers = poNumberFilteredCustomers.filter((workOrder) =>
        workOrder.customer_name?.toLowerCase().includes(tableFilters.customer?.toLowerCase()),
      );

      return customerFilteredCustomers;
    }

    return workOrders;
  };

  return (
    <>
      <Grid justifyContent="space-between" container spacing={3}>
        <Grid item>
          <Typography variant="h1" component="h1">
            Work Order Management
          </Typography>
          <br />
        </Grid>
      </Grid>
      <br />
      {fitlerBarActive && (
        <Grid justifyContent="space-between" container>
          <Grid item md={12}>
            <WorkOrderAutoBulkFilterBar
              machineChoices={autoBulkMachines}
              machineChoicesLoading={machinesLoading}
              tableFilters={tableFilters}
              setTableFilters={setTableFilters}
            />
            <br />
          </Grid>
        </Grid>
      )}
      <br />
      <>
        <Card>
          <CardContent>
            <Grid justifyContent="right" container spacing={0}>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={activeMachine?.value === 'Select A Machine' || activeMachine?.value === undefined}
                  onClick={() => {
                    setCreatingPage(true);
                    setFitlerBarActive(false);
                  }}
                >
                  New Order
                </Button>
              </Grid>
            </Grid>
            {activeMachine && activeMachine?.value !== 'Select A Machine' ? (
              <DataTable
                noHeader
                columns={columns}
                data={filterCustomers()}
                defaultSortField="date_and_time_last_modified"
                defaultSortAsc={false}
                striped
                onRowClicked={(row: WorkOrderAutoBulk) => {
                  setActiveWorkOrderAutoBulk(row as any);
                  setDetailPage(true);
                  setFitlerBarActive(false);
                  // setActiveMachine(activeMachine);
                }}
                highlightOnHover
                pointerOnHover
                pagination
                paginationPerPage={10}
                paginationRowsPerPageOptions={[10, 25, 50]}
              />
            ) : (
              <Grid container alignItems="center" justifyContent="center">
                <Typography variant="h2" component="h2" style={{ marginTop: 15 }}>
                  Please select a machine to view details.
                </Typography>
              </Grid>
            )}
          </CardContent>
          <br />
        </Card>
      </>
    </>
  );
};

export default WorkOrdersAutoBulkList;
