import React, { Dispatch, SetStateAction } from 'react';
import axios from '../../../modules/utils/axios.utils';
import { MACHINE_MANAGEMENT_RETRY_INTERVAL, MACHINE_MANAGEMENT_TOTAL_RETRIES } from '../../../modules/utils/env';
import { SnackBarContext } from '../../../modules/snackBar/contexts/SnackBarContext';

interface RecipesAutoTreatContextInterface {
  fetchCachedRecipesAutoTreat?: (machineSerial: string) => Promise<void>;
  fetchRecipesAutoTreat?: (machineSerial: string, onSuccess?: () => void) => Promise<void>;
  recipesRefreshedAt?: string;
  recipes?: any[];
  recipesLoading?: boolean;

  detailPage?: boolean;
  setDetailPage?: Dispatch<SetStateAction<boolean>>;

  setActiveMachine?: Dispatch<SetStateAction<Record<string, string>>>;
  activeMachine?: Record<string, string>;

  setActiveRecipeAutoTreat?: Dispatch<SetStateAction<Record<string, string>>>;
  activeRecipeAutoTreat?: Record<string, string>;

  handleGenericFilterChange?: (genericFilter: string) => Promise<void>;
  genericFilter?: string;

  handleStatusFilterChange?: (statusFilter: string) => Promise<void>;
  statusFilter?: string;

  handleApiLinkedFilterChange?: (apiLinkedFilter: string) => Promise<void>;
  apiLinkedFilter?: string;
}

const RecipesAutoTreatContext = React.createContext<RecipesAutoTreatContextInterface>({});

const RecipesAutoTreatContextConsumer = RecipesAutoTreatContext.Consumer;
const RecipesAutoTreatContextProvider: React.FC = ({ children }) => {
  const { showErrorSnackBar } = React.useContext(SnackBarContext);

  const [recipes, setRecipesAutoTreat] = React.useState([]);
  const [recipesLoading, setRecipesAutoTreatLoading] = React.useState(true);
  const [recipesRefreshedAt, setRecipesAutoTreatRefreshedAt] = React.useState<string>();
  const [activeRecipeAutoTreat, setActiveRecipeAutoTreat] = React.useState({});
  const [detailPage, setDetailPage] = React.useState<boolean>(false);
  const [activeMachine, setActiveMachine] = React.useState<Record<string, string>>({
    label: localStorage.getItem('machineManagementAutoTreatMachine') || 'Select A Machine',
    value: localStorage.getItem('machineManagementAutoTreatMachine') || 'Select A Machine',
  });
  const [genericFilter, setGenericFilter] = React.useState<string>('');
  const [statusFilter, setStatusFilter] = React.useState<string>('Active');
  const [apiLinkedFilter, setApiLinkedFilter] = React.useState<string>('All');

  React.useEffect(() => {
    if (activeMachine?.value === 'Select A Machine') setRecipesAutoTreatLoading(false);
  }, [activeMachine]);

  const fetchCachedRecipesAutoTreat = async (machineSerial: string) => {
    setRecipesAutoTreatLoading(true);
    setRecipesAutoTreatRefreshedAt(null);
    const retryCount = 0;

    axios
      .get<string, any>(`api/autotreat/onprem/recipes/${machineSerial}/`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      })
      .then((response) => {
        setRecipesAutoTreatLoading(false);
        setRecipesAutoTreat(response.data.results);
        setRecipesAutoTreatRefreshedAt(response.data.created_at);
      })
      .catch((error) => {
        setRecipesAutoTreatLoading(false);
        setRecipesAutoTreat([]);
        setRecipesAutoTreatRefreshedAt(null);
      });
  };

  const fetchRecipesAutoTreat = async (machineSerial: string, onSuccess?: () => void) => {
    setRecipesAutoTreatLoading(true);
    setRecipesAutoTreatRefreshedAt(null);
    let retryCount = 0;

    axios
      .get<string, any>(
        `api/autotreat/onprem/recipes/?serial-number=${machineSerial}&generic-filter=${genericFilter}&status=${statusFilter}&api-linked=${apiLinkedFilter}`,
        {
          headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
        },
      )

      .then((res) => {
        const fetchTimer = window.setInterval(() => {
          axios
            .get<string, any>(`api/autotreat/onprem/recipes/${res.data.result}`, {
              headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
            })
            .then((response) => {
              window.clearTimeout(fetchTimer);
              setRecipesAutoTreatLoading(false);
              setRecipesAutoTreat(response.data.results);
              setRecipesAutoTreatRefreshedAt(response.data.created_at);
            })
            .catch((error) => {
              retryCount += 1;

              if (retryCount > MACHINE_MANAGEMENT_TOTAL_RETRIES) {
                window.clearInterval(fetchTimer);
                showErrorSnackBar('Unable to load recipes');
                setRecipesAutoTreatLoading(false);
                setRecipesAutoTreat([]);
                setRecipesAutoTreatRefreshedAt(null);
              }
            });
        }, MACHINE_MANAGEMENT_RETRY_INTERVAL);
      });
  };

  const handleGenericFilterChange = async (genericFilter: string) => {
    setGenericFilter(genericFilter);
  };

  const handleStatusFilterChange = async (statusFilter: string) => {
    setStatusFilter(statusFilter);
  };

  const handleApiLinkedFilterChange = async (apiLinkedFilter: string) => {
    setApiLinkedFilter(apiLinkedFilter);
  };

  return (
    <RecipesAutoTreatContext.Provider
      value={{
        fetchCachedRecipesAutoTreat,
        fetchRecipesAutoTreat,
        recipes,
        recipesRefreshedAt,
        recipesLoading,

        detailPage,
        setDetailPage,

        setActiveRecipeAutoTreat,
        activeRecipeAutoTreat,

        setActiveMachine,
        activeMachine,

        handleGenericFilterChange,
        genericFilter,
        handleStatusFilterChange,
        statusFilter,
        handleApiLinkedFilterChange,
        apiLinkedFilter,
      }}
    >
      {children}
    </RecipesAutoTreatContext.Provider>
  );
};

export { RecipesAutoTreatContextProvider, RecipesAutoTreatContextConsumer, RecipesAutoTreatContext };
