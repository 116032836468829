/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axios from '../../utils/axios.utils';
import { APPLICATION_URL, AUTH_ZERO_AUDIENCE } from '../../utils/env';

interface AuthContextInterface {
  handleLogout?: any;
  accessToken?: string;
  permissionsLoading?: boolean;
  currentUserHasUserPermission?: (permission: string) => boolean;
  currentUserHasAdminPermission?: (permission: string) => boolean;
  sidebarLoading?: boolean;
  hasAutoBulkMachines?: boolean;
  hasAutoTreatMachines?: boolean;
  levelSensorsModuleEnabled?: boolean;
  companyManaged?: boolean;
  loginMessage?: string;
  manageIntegrationRecords?: boolean;
}

const AuthContext = React.createContext<AuthContextInterface>({});

const AuthContextConsumer = AuthContext.Consumer;
const AuthContextProvider: React.FC = ({ children }) => {
  const [loginMessage, setLoginMessage] = React.useState<string>();
  const [permissionsLoading, setPermissionsLoading] = React.useState(true);
  const [userRole, setUserRole] = React.useState(undefined);
  const [adminRole, setAdminRole] = React.useState(undefined);
  const [manageIntegrationRecords, setManageIntegrationRecords] = React.useState(true);
  const [defaultShowToggle, setDefaultShowToggle] = React.useState(undefined);
  const [defaultMachineNavigation, setDefaultMachineNavigation] = React.useState(undefined);
  const [accessToken, setAccessToken] = React.useState('');
  const [levelSensorsModuleEnabled, setLevelSensorsModuleEnabled] = React.useState(
    sessionStorage.getItem('levelSensorsModuleEnabled') === 'true',
  );
  const [companyManaged, setCompanyManaged] = React.useState(sessionStorage.getItem('companyManaged') === 'true');
  const [sidebarLoading, setSidebarLoading] = React.useState(false);

  const [hasAutoBulkMachines, setHasAutoBulkMachines] = React.useState(
    sessionStorage.getItem('has_autobulk_machines') === 'true',
  );
  const [hasAutoTreatMachines, setHasAutoTreatMachines] = React.useState(
    sessionStorage.getItem('has_autobulk_machines') === 'true',
  );

  const { getAccessTokenSilently, logout } = useAuth0();

  const handleLogout = useCallback(() => {
    sessionStorage.removeItem('userRole');
    sessionStorage.removeItem('adminRole');
    logout({ returnTo: `${APPLICATION_URL}/login` } as any);
  }, [logout]);

  // Inside your AuthContextProvider or a component that listens to this event
  useEffect(() => {
    const handleLogoutEvent = () => {
      handleLogout();
    };

    window.addEventListener('logoutRequired', handleLogoutEvent);

    return () => {
      window.removeEventListener('logoutRequired', handleLogoutEvent);
    };
  }, [handleLogout]);

  const currentUserHasAdminPermission = (permission: string) => {
    if (permission === 'ksi_superadmin') {
      if ([1].includes(adminRole?.permission_group_id)) {
        return true;
      }
    }

    if (permission === 'ksi_admin') {
      if ([1, 10].includes(adminRole?.permission_group_id)) {
        return true;
      }
    }

    if (permission === 'ksi_support') {
      if ([1, 10, 4].includes(adminRole?.permission_group_id)) {
        return true;
      }
    }

    return false;
  };

  const currentUserHasUserPermission = (permission: string) => {
    if (permission === 'company_super_admin') {
      if ([5].includes(userRole.permission_group_id)) {
        return true;
      }
    }

    if (permission === 'default_user') {
      if ([5, 6].includes(userRole.permission_group_id)) {
        return true;
      }
    }

    if (permission === 'default_user_view_only') {
      if ([5, 6, 7].includes(userRole.permission_group_id)) {
        return true;
      }
    }

    if (permission === 'data_management') {
      if ([5, 6, 7, 8].includes(userRole.permission_group_id)) {
        return true;
      }
    }

    if (permission === 'reporting') {
      if ([5, 6, 7, 8, 9].includes(userRole.permission_group_id)) {
        return true;
      }
    }

    return false;
  };

  useEffect(() => {
    async function fetchUser() {
      let tempToken = '';

      try {
        tempToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: AUTH_ZERO_AUDIENCE, // Value in Identifier field for the API being called.
            scope: 'read:transactions', // Scope that exists for the API being called. You can create these through the Auth0 Management API or through the Auth0 Dashboard in the Permissions view of your API.
          },
        });
        const result = await axios.get<any>(`api/user/sessions/`, {
          headers: {
            Authorization: `Bearer ${tempToken}`,
          },
        });

        const sidebarResult = await axios.get<string, any>(`api/user/sidebar_pages/`, {
          headers: {
            Authorization: `Bearer ${tempToken}`,
          },
        });

        setSidebarLoading(false);

        if (sessionStorage.getItem('showAutoTreatSidebar') === null) {
          if (sidebarResult.data.default_machine_navigation === 'autotreat') {
            sessionStorage.setItem('showAutoTreatSidebar', 'true');
            // history.push('/autotreat/analytics/transaction_summary');
          } else {
            sessionStorage.setItem('showAutoTreatSidebar', 'false');
            // history.push('/autobulk/analytics/transaction_summary');
          }

          sessionStorage.setItem('has_autobulk_machines', sidebarResult.data.has_autobulk_machines.toString());
          sessionStorage.setItem('has_autotreat_machines', sidebarResult.data.has_autotreat_machines.toString());

          setHasAutoBulkMachines(sidebarResult.data.has_autobulk_machines);
          setHasAutoTreatMachines(sidebarResult.data.has_autotreat_machines);
        } else {
          sessionStorage.setItem('has_autobulk_machines', sidebarResult.data.has_autobulk_machines.toString());
          sessionStorage.setItem('has_autotreat_machines', sidebarResult.data.has_autotreat_machines.toString());

          setHasAutoBulkMachines(sidebarResult.data.has_autobulk_machines);
          setHasAutoTreatMachines(sidebarResult.data.has_autotreat_machines);
        }

        setManageIntegrationRecords(sidebarResult.data.manage_integration_records);
        sessionStorage.setItem('manageIntegrationRecords', sidebarResult.data.manage_integration_records);
        sessionStorage.setItem('accessToken', tempToken);
        sessionStorage.setItem('userRole', JSON.stringify(result.data.user_role));
        sessionStorage.setItem('adminRole', JSON.stringify(result.data.admin_role));
        sessionStorage.setItem('defaultMachineNavigation', JSON.stringify(result.data.default_machine_navigation));
        sessionStorage.setItem('showToggle', JSON.stringify(result.data.show_toggle));

        setCompanyManaged(sidebarResult.data.company_managed);
        sessionStorage.setItem('companyManaged', sidebarResult.data.company_managed.toString());

        setLevelSensorsModuleEnabled(sidebarResult.data.level_sensors_module_enabled);
        sessionStorage.setItem('levelSensorsModuleEnabled', sidebarResult.data.level_sensors_module_enabled.toString());

        setDefaultShowToggle(result.data.show_toggle || false);
        setDefaultMachineNavigation(result.data.default_machine_navigation || 'autotreat');
        setUserRole(result.data.user_role || {});
        setAdminRole(result.data.admin_role || {});
        setPermissionsLoading(false);
      } catch (error: any) {
        setLoginMessage(error.message);
      }
    }

    if (sessionStorage.getItem('userRole') && sessionStorage.getItem('adminRole')) {
      setUserRole(JSON.parse(sessionStorage.getItem('userRole')));
      setAdminRole(JSON.parse(sessionStorage.getItem('adminRole')));
      setPermissionsLoading(false);
    } else {
      fetchUser();
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{
        handleLogout,
        accessToken,
        permissionsLoading,
        currentUserHasUserPermission,
        currentUserHasAdminPermission,
        sidebarLoading,
        hasAutoBulkMachines,
        hasAutoTreatMachines,
        levelSensorsModuleEnabled,
        companyManaged,
        loginMessage,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
export { AuthContextProvider, AuthContextConsumer, AuthContext };
