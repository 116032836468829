import React, { Dispatch, SetStateAction } from 'react';
import axios from '../../../modules/utils/axios.utils';
import { MACHINE_MANAGEMENT_RETRY_INTERVAL, MACHINE_MANAGEMENT_TOTAL_RETRIES } from '../../../modules/utils/env';
import { SnackBarContext } from '../../../modules/snackBar/contexts/SnackBarContext';

interface RfidsAutoBulkContextInterface {
  fetchCachedRfidsAutoBulk?: (machineSerial: string) => Promise<void>;
  fetchRfidsAutoBulk?: (machineSerial: string, genericFilter: string, onSuccess?: () => void) => Promise<void>;
  rfidsRefreshedAt?: string;
  rfids?: any[];
  rfidsLoading?: boolean;

  createRfidAutoBulk?: (formValues: any, onSuccess?: () => void) => Promise<void>;
  updateRfidAutoBulk?: (formValues: any) => Promise<void>;

  creatingPage?: boolean;
  setCreatingPage?: Dispatch<SetStateAction<boolean>>;
  detailPage?: boolean;
  setDetailPage?: Dispatch<SetStateAction<boolean>>;

  setRfidsubmitting?: Dispatch<SetStateAction<boolean>>;
  rfidsubmitting?: boolean;

  setActiveMachine?: any;
  activeMachine?: Record<string, string>;

  setActiveRfidAutoBulk?: Dispatch<SetStateAction<Record<string, string>>>;
  activeRfidAutoBulk?: Record<string, string>;

  handleGenericFilterChange?: (genericFilter: string) => Promise<void>;
  genericFilter?: string;
}

const RfidsAutoBulkContext = React.createContext<RfidsAutoBulkContextInterface>({});

const RfidsAutoBulkContextConsumer = RfidsAutoBulkContext.Consumer;
const RfidsAutoBulkContextProvider: React.FC = ({ children }) => {
  const { showErrorSnackBar, showSuccessSnackBar, showMachineManagementSnackBar } = React.useContext(SnackBarContext);

  const [rfids, setRfidsAutoBulk] = React.useState([]);
  const [rfidsLoading, setRfidsAutoBulkLoading] = React.useState(true);
  const [rfidsRefreshedAt, setRfidsAutoBulkRefreshedAt] = React.useState<string>();
  const [activeRfidAutoBulk, setActiveRfidAutoBulk] = React.useState({});
  const [creatingPage, setCreatingPage] = React.useState<boolean>(false);
  const [detailPage, setDetailPage] = React.useState<boolean>(false);
  const [activeMachine, setActiveMachine] = React.useState<Record<string, string>>({
    label: localStorage.getItem('machineManagementAutoBulkMachine') || 'Select A Machine',
    value: localStorage.getItem('machineManagementAutoBulkMachine') || 'Select A Machine',
  });
  const [genericFilter, setGenericFilter] = React.useState<string>('');
  const [rfidsubmitting, setRfidsubmitting] = React.useState(false);

  React.useEffect(() => {
    if (activeMachine?.value === 'Select A Machine') setRfidsAutoBulkLoading(false);
  }, [activeMachine]);

  const fetchCachedRfidsAutoBulk = async (machineSerial: string) => {
    const accessToken = sessionStorage.getItem('accessToken');

    setRfidsAutoBulkLoading(true);
    setRfidsAutoBulkRefreshedAt(null);
    const retryCount = 0;

    axios
      .get<string, any>(`api/autobulk/onprem/rfids/${machineSerial}/`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((response) => {
        setRfidsAutoBulkLoading(false);
        setRfidsAutoBulk(response.data.results);
        setRfidsAutoBulkRefreshedAt(response.data.created_at);
      })
      .catch((error) => {
        setRfidsAutoBulkLoading(false);
        setRfidsAutoBulk([]);
        setRfidsAutoBulkRefreshedAt(null);
      });
  };

  const fetchRfidsAutoBulk = async (machineSerial: string, genericFilter: string, onSuccess?: () => void) => {
    const accessToken = sessionStorage.getItem('accessToken');

    setRfidsAutoBulkLoading(true);
    setRfidsAutoBulkRefreshedAt(null);
    let retryCount = 0;

    axios
      .get<string, any>(`api/autobulk/onprem/rfids/?serial-number=${machineSerial}&generic-filter=${genericFilter}`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((res) => {
        const fetchTimer = window.setInterval(() => {
          axios
            .get<string, any>(`api/autobulk/onprem/rfids/${res.data.result}/`, {
              headers: { Authorization: `Bearer ${accessToken}` },
            })
            .then((response) => {
              window.clearInterval(fetchTimer);
              setRfidsAutoBulkLoading(false);
              setRfidsAutoBulk(response.data.results);
              setRfidsAutoBulkRefreshedAt(response.data.created_at);
            })
            .catch((error) => {
              retryCount += 1;

              if (retryCount > MACHINE_MANAGEMENT_TOTAL_RETRIES) {
                window.clearInterval(fetchTimer);
                setRfidsAutoBulkLoading(false);
                showErrorSnackBar('Unable to load rfids');
                setRfidsAutoBulk([]);
                setRfidsAutoBulkRefreshedAt(null);
              }
            });
        }, MACHINE_MANAGEMENT_RETRY_INTERVAL);
      });
  };

  const createRfidAutoBulk = async (formValues: any, onSuccess?: () => void) => {
    setRfidsubmitting(true);
    setRfidsAutoBulkLoading(true);
    setRfidsAutoBulkRefreshedAt(null);
    showSuccessSnackBar('Sending record to the machine.');
    let retryCount = 0;

    const accessToken = sessionStorage.getItem('accessToken');

    axios
      .post<string, any>(
        `api/autobulk/onprem/rfids/?serial-number=${activeMachine?.value}`,
        { ...formValues },
        { headers: { Authorization: `Bearer ${accessToken}` } },
      )
      .then((res) => {
        const fetchTimer = window.setInterval(() => {
          axios
            .get<string, any>(`api/autobulk/onprem/rfids/${res.data.result}/`, {
              headers: { Authorization: `Bearer ${accessToken}` },
            })
            .then((response) => {
              window.clearInterval(fetchTimer);
              setRfidsAutoBulkLoading(false);
              showMachineManagementSnackBar(response.data.results[0]);
              fetchRfidsAutoBulk(activeMachine?.value, '');
              setRfidsubmitting(false);
            })
            .catch((error) => {
              retryCount += 1;

              if (retryCount > MACHINE_MANAGEMENT_TOTAL_RETRIES) {
                window.clearInterval(fetchTimer);
                setRfidsAutoBulkLoading(false);
                setRfidsubmitting(false);
                showErrorSnackBar('No response from machine');
                setRfidsAutoBulk([]);
                setRfidsAutoBulkRefreshedAt(null);
              }
            });
        }, MACHINE_MANAGEMENT_RETRY_INTERVAL);
      });
  };

  const updateRfidAutoBulk = async (formValues: any) => {
    setRfidsubmitting(true);
    setRfidsAutoBulkLoading(true);
    setRfidsAutoBulkRefreshedAt(null);
    showSuccessSnackBar('Sending update to the machine.');
    let retryCount = 0;

    const accessToken = sessionStorage.getItem('accessToken');

    axios
      .put<string, any>(
        `api/autobulk/onprem/rfids/0/?serial-number=${activeMachine?.value}`,
        { ...formValues },
        { headers: { Authorization: `Bearer ${accessToken}` } },
      )
      .then((res) => {
        const fetchTimer = window.setInterval(() => {
          axios
            .get<string, any>(`api/autobulk/onprem/rfids/${res.data.result}/`, {
              headers: { Authorization: `Bearer ${accessToken}` },
            })
            .then((response) => {
              window.clearInterval(fetchTimer);
              setRfidsAutoBulkLoading(false);
              showMachineManagementSnackBar(response.data.results[0]);
              fetchRfidsAutoBulk(activeMachine?.value, '');
              setRfidsubmitting(false);
            })
            .catch((error) => {
              retryCount += 1;

              if (retryCount > MACHINE_MANAGEMENT_TOTAL_RETRIES) {
                window.clearInterval(fetchTimer);
                setRfidsAutoBulkLoading(false);
                setRfidsubmitting(false);
                showErrorSnackBar('No response from machine');
                setRfidsAutoBulk([]);
                setRfidsAutoBulkRefreshedAt(null);
              }
            });
        }, MACHINE_MANAGEMENT_RETRY_INTERVAL);
      });
  };

  const handleGenericFilterChange = async (genericFilter: string) => {
    setGenericFilter(genericFilter);
  };

  return (
    <RfidsAutoBulkContext.Provider
      value={{
        fetchCachedRfidsAutoBulk,
        fetchRfidsAutoBulk,
        rfids,
        rfidsRefreshedAt,
        rfidsLoading,

        createRfidAutoBulk,

        updateRfidAutoBulk,

        creatingPage,
        setCreatingPage,
        detailPage,
        setDetailPage,

        rfidsubmitting,

        setActiveRfidAutoBulk,
        activeRfidAutoBulk,

        setActiveMachine,
        activeMachine,

        handleGenericFilterChange,
        genericFilter,
      }}
    >
      {children}
    </RfidsAutoBulkContext.Provider>
  );
};

export { RfidsAutoBulkContextProvider, RfidsAutoBulkContextConsumer, RfidsAutoBulkContext };
