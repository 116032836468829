import React, { useEffect, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Card,
  CardContent,
  Typography,
  Grid,
  IconButton,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import DataTable from '../../dataTable/DataTable/DataTable';
import { MachineCertificatesContext } from '../contexts/MachineCertificatesContext';
import { AuthContext } from '../../auth/contexts/AuthContext';
import columns from '../constants/columns';

const MachineCertificateListPage = () => {
  const history = useHistory();
  const { currentUserHasUserPermission } = useContext(AuthContext);
  const {
    fetchMachineCertificates,
    machineCertificates,
    machineCertificatesLoading,
    rejectMachineCertificate,
    approveMachineCertificate,
  } = useContext(MachineCertificatesContext);

  const [perPage, setPerPage] = useState(10);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmAction, setConfirmAction] = useState('');
  const [selectedCertificateId, setSelectedCertificateId] = useState('');

  useEffect(() => {
    fetchMachineCertificates();
  }, []);

  const handlePageChange = (page) => {
    fetchMachineCertificates(page, perPage);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
    fetchMachineCertificates(page, newPerPage);
  };

  const handleApprove = async (machineCertificateId) => {
    await approveMachineCertificate(machineCertificateId);
    fetchMachineCertificates();
  };

  const handleReject = async (machineCertificateId) => {
    await rejectMachineCertificate(machineCertificateId);
    fetchMachineCertificates();
  };

  const actionColumns = [
    {
      name: 'Actions',
      cell: (row) => (
        <>
          <Tooltip title="Approve">
            <IconButton
              color="primary"
              onClick={(e) => {
                e.stopPropagation();
                setConfirmAction('approve');
                setSelectedCertificateId(row.machine_certificate_id);
                setConfirmOpen(true);
              }}
            >
              <CheckIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Reject">
            <IconButton
              color="secondary"
              onClick={(e) => {
                e.stopPropagation();
                setConfirmAction('reject');
                setSelectedCertificateId(row.machine_certificate_id);
                setConfirmOpen(true);
              }}
              style={{ marginLeft: '8px' }}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </>
      ),
    },
  ];

  const combinedColumns = [...columns, ...actionColumns];

  return (
    <>
      <Dialog
        open={confirmOpen}
        onClose={() => setConfirmOpen(false)}
        aria-labelledby="confirm-dialog-title"
        aria-describedby="confirm-dialog-description"
      >
        <DialogTitle id="confirm-dialog-title">
          {confirmAction === 'approve' ? 'Approve Machine Certificate' : 'Reject Machine Certificate'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="confirm-dialog-description">
            Are you sure you want to {confirmAction === 'approve' ? 'approve' : 'reject'} this machine certificate?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmOpen(false)} color="primary">
            Cancel
          </Button>
          <Button
            onClick={async () => {
              setConfirmOpen(false);
              if (confirmAction === 'approve') {
                await handleApprove(selectedCertificateId);
              } else if (confirmAction === 'reject') {
                await handleReject(selectedCertificateId);
              }
            }}
            color={confirmAction === 'approve' ? 'primary' : 'secondary'}
          >
            {confirmAction === 'approve' ? 'Approve' : 'Reject'}
          </Button>
        </DialogActions>
      </Dialog>
      <Grid justifyContent="space-between" container spacing={3}>
        <Grid item>
          <Typography variant="h1" component="h1">
            Pending Machine Certificates
          </Typography>
        </Grid>
      </Grid>
      <br />
      <Card>
        <CardContent>
          <DataTable
            noHeader
            columns={combinedColumns}
            data={machineCertificates.filter((certificate) => certificate.certificate_state === 'pending')}
            defaultSortAsc={false}
            striped
            onRowClicked={(row) => history.push(`/machines/machine_certificates/${row.machine_certificate_id}`)}
            highlightOnHover
            pointerOnHover
            progressPending={machineCertificatesLoading}
            pagination
          />
        </CardContent>
        <br />
      </Card>
      <br />
      <Grid justifyContent="space-between" container spacing={3}>
        <Grid item>
          <Typography variant="h1" component="h1">
            All Machine Certificates
          </Typography>
        </Grid>
      </Grid>
      <Card>
        <CardContent>
          <DataTable
            noHeader
            columns={columns}
            data={machineCertificates}
            defaultSortAsc={false}
            striped
            onRowClicked={(row) => history.push(`/machines/machine_certificates/${row.machine_certificate_id}`)}
            highlightOnHover
            pointerOnHover
            progressPending={machineCertificatesLoading}
            pagination
          />
        </CardContent>
        <br />
      </Card>
    </>
  );
};

export default MachineCertificateListPage;
