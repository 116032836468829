import React from 'react';
import { Button, Grid, GridSize } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { SelectOption } from '../../../modules/shared/model';
import AutocompleteFilter from '../../../modules/shared/form/AutocompleteFilter';
import SelectInput from '../../../modules/shared/form/SelectInput';
import FormInput from '../../../modules/shared/form/FormInput';
import { RfidsAutoBulkContext } from '../contexts/RfidAutoBulkContext';
import convertToLocalTime from '../../../modules/shared/utils/convertToLocalTime';

export interface FilterProps {
  label: string;
  pluralLabel: string;
  selector: string;
  width: boolean | GridSize;
  type: string;
  optionSelector: string;
  options?: SelectOption[];
}

interface RfidAutoBulkFilterBarProps {
  filters?: FilterProps[];
  filterValues?: any;
  machineChoices?: any;
  machineChoicesLoading?: boolean;
  filterChoices?: any;
}

const RfidAutoBulkFilterBar: React.FC<RfidAutoBulkFilterBarProps> = (props) => {
  const { filters = [], filterValues, filterChoices, machineChoices, machineChoicesLoading } = props;
  const [temporaryValues, setTemporaryValues] = React.useState<Record<string, any[]>>(filterValues);

  const {
    rfids,
    fetchRfidsAutoBulk,
    fetchCachedRfidsAutoBulk,
    rfidsLoading,
    rfidsRefreshedAt,
    activeMachine,
    setActiveMachine,
    handleGenericFilterChange,
    genericFilter,
  } = React.useContext(RfidsAutoBulkContext);

  React.useEffect(() => {
    if (rfids.length === 0 && localStorage.getItem('machineManagementAutoBulkMachine')) {
      fetchCachedRfidsAutoBulk(localStorage.getItem('machineManagementAutoBulkMachine'));
    }
  }, []);

  const handleMachineChange = (value: string) => {
    localStorage.setItem('machineManagementAutoBulkMachine', value);
    fetchCachedRfidsAutoBulk(value);
    setActiveMachine(machineChoices.filter((choice) => choice?.value === value)?.[0]);
  };

  const defaultMachineChoice = () => {
    if (machineChoicesLoading) {
      return { label: 'Loading...', value: 'Select A Machine' };
    }

    return { label: 'Select A Machine', value: 'Select A Machine' };
  };

  const handleCancel = () => {
    window.location.reload();
  };

  return (
    <Grid container spacing={1} alignItems="flex-start">
      <Grid item xs={12}>
        <Grid container spacing={1} alignItems="flex-start">
          <Grid item md={2} xs={6}>
            <SelectInput
              value={activeMachine?.value}
              groupedChoices={{
                'Online Machines': machineChoices.filter((machine) => machine.online_status === 'online'),
                'Offline Machines': machineChoices.filter((machine) => machine.online_status === 'offline'),
                'Expired Machines': machineChoices.filter((machine) => machine.subscription_active === false),
              }}
              defaultChoice={defaultMachineChoice()}
              onChange={handleMachineChange}
              label="Machine"
              customPadding="8px 27px 9px 15px"
            />
          </Grid>
          <Grid item md={2} xs={6}>
            <FormInput
              value={genericFilter}
              onChange={(value) => handleGenericFilterChange(value)}
              label="Generic Filter"
            />
          </Grid>
          {filters.map((filter) => (
            <React.Fragment key={filter.label}>
              <Grid item>
                <AutocompleteFilter
                  label={filter.label}
                  loading={false}
                  pluralLabel={filter.pluralLabel}
                  choices={filter?.options || filterChoices[filter?.optionSelector] || []}
                  setValue={(value) => {
                    setTemporaryValues({ ...temporaryValues, [filter?.selector]: value });
                  }}
                  value={temporaryValues[filter?.selector] as string[]}
                />
              </Grid>
            </React.Fragment>
          ))}
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              style={{ color: '#fff', marginTop: 19 }}
              disableElevation
              disabled={rfidsLoading || activeMachine?.value === 'Select A Machine'}
              onClick={() => {
                fetchRfidsAutoBulk(activeMachine?.value, genericFilter);
              }}
            >
              {rfidsRefreshedAt ? 'Load' : 'Load'}
            </Button>
            <Button
              variant="contained"
              color="primary"
              style={{ color: '#fff', marginTop: 19, marginLeft: 13 }}
              disableElevation
              disabled={!rfidsLoading}
              onClick={() => {
                handleCancel();
              }}
            >
              Cancel
            </Button>
            {rfidsLoading ? (
              <span style={{ marginLeft: 20, position: 'relative', top: 19 }}>
                <CircularProgress style={{ width: 30, height: 30 }} />
              </span>
            ) : (
              <span style={{ marginLeft: 7, position: 'relative', top: 10 }}>
                Last Loaded: {convertToLocalTime(rfidsRefreshedAt)}
              </span>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default RfidAutoBulkFilterBar;
