import React from 'react';
import { makeStyles } from '@mui/styles';
import { Frame } from '@shopify/polaris';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

import { AuthContext } from './modules/auth/contexts/AuthContext';
import { APPLICATION_URL } from './modules/utils/env';
import SupportModal from './modules/supportBot/components/SupportModal';

import SourcesAutoBulkPage from './autobulkModules/sources/pages/SourcesAutoBulkPage';
import WorkOrdersAutoBulkPage from './autobulkModules/workOrders/pages/WorkOrdersAutoBulkPage';
import WorkOrdersAutoBulkDetailPage from './autobulkModules/workOrders/pages/WorkOrdersAutoBulkDetailPage';

import TransportsAutoBulkPage from './autobulkModules/transports/pages/TransportsAutoBulkPage';
import RfidsAutoBulkPage from './autobulkModules/rfid/pages/RfidAutoBulkPage';
import DriversAutoBulkPage from './autobulkModules/drivers/pages/DriversAutoBulkPage';
import TransactionApiLogsAutoBulkPage from './autobulkModules/transactionsApiLog/pages/TransactionApiLogsAutoBulkPage';

import UserAllMachinesListPage from './modules/machinesAccountManagement/pages/UserAllMachinesList';
import UserAllMachinesDetailPage from './modules/machinesAccountManagement/pages/UserAllMachinesDetail';
import UserMachinesListPage from './modules/machinesAccountManagement/pages/UserMachinesList';
import UserMachineDetailPage from './modules/machinesAccountManagement/pages/UserMachineDetail';
import UserCompanyDetailPage from './modules/companiesAccountManagement/pages/UserCompanyDetailsPage';

import MachineDashboardPage from './modules/machineDashboard/pages/MachineDashboardPage';

import AdminMachineCertificatesListPage from './modules/machineCertificates/pages/MachineCertificateListPage';
import AdminMachinesListPage from './modules/machinesAdminManagement/pages/AdminMachinesList';
import AdminMachineDetailPage from './modules/machinesAdminManagement/pages/AdminMachineDetail';
import AdminNewMachinePage from './modules/machinesAdminManagement/pages/NewMachine';
import AdminMachineExecuteSql from './modules/machinesAdminManagement/pages/AdminMachineExecuteSql';
import AdminAnalyticsPage from './modules/adminAnalytics/pages/AdminAnalyticsPage';
import AdminCloudExecuteSql from './modules/auditing/pages/AdminCloudExecuteSql';
import AuditingDetail from './modules/auditing/pages/AuditingDetail';

import AdminUsersListPage from './modules/adminUsers/pages/UserListPage';
import AdminUserDetailPage from './modules/adminUsers/pages/UserDetailPage';
import AdminNewUserPage from './modules/adminUsers/pages/UserNewPage';

import UserUsersListPage from './modules/userUsers/pages/UserListPage';
import UserUserDetailPage from './modules/userUsers/pages/UserDetailPage';
import UserUserNewPage from './modules/userUsers/pages/UserNewPage';

import PermissionGroupsListPage from './modules/permissionGroups/pages/PermissionGroupsListPage';
import EditPermissionGroupPage from './modules/permissionGroups/pages/EditPermissionGroupPage';

import CompaniesListPage from './modules/companiesAdminManagement/pages/CompaniesListPage';
import CompanyDetailsPage from './modules/companiesAdminManagement/pages/CompanyDetailsPage';
import NewCompanyPage from './modules/companiesAdminManagement/pages/NewCompanyPage';

import AutoTreatTransactionSummary from './autotreatModules/transactions/pages/AutoTreatTransactionSummary';
import AutoTreatTreatmentProductSummary from './autotreatModules/transactions/pages/AutoTreatTreatmentProductSummary';
import SeedVarietySummary from './autotreatModules/transactions/pages/SeedVarietySummary';

import AutoBulkTransactionSummary from './autobulkModules/transactions/pages/AutoBulkTransactionSummary';
import SourceSummary from './autobulkModules/transactions/pages/SourceSummary';
import ProductSummary from './autobulkModules/transactions/pages/ProductSummary';
import ProductDetailPage from './autobulkModules/transactions/pages/ProductDetailPage';

import RealTimeActivity from './autobulkModules/realTimeDashboard/pages/RealTimeActivity';
import AutoTreatRealTimeActivity from './autotreatModules/realTimeDashboard/pages/AutoTreatRealTimeActivity';

import CustomersAutoBulkListPage from './autobulkModules/customersAutoBulk/pages/CustomersAutoBulkPage';
import ProductsAutoBulkDetailRedirectPage from './autobulkModules/products/pages/ProductsAutoBulkDetailRedirectPage';
import ProductsAutoBulkPage from './autobulkModules/products/pages/ProductsAutoBulkPage';

import CustomersAutoTreatPage from './autotreatModules/customers/pages/CustomersAutoTreatPage';
import CustomersAutoTreatcsvImportPage from './autotreatModules/customers/pages/CustomersAutoTreatcsvImportPage';
import CustomersAutoTreatcsvPartnerImportPage from './autotreatModules/customers/pages/CustomersAutoTreatcsvPartnerImportPage';
import SeedsAutoTreatPage from './autotreatModules/seeds/pages/SeedsAutoTreatPage';
import TreatmentProductsAutoTreatPage from './autotreatModules/treatmentProducts/pages/TreatmentProductsAutoTreatPage';
import RecipesAutoTreatPage from './autotreatModules/recipes/pages/RecipesAutoTreatPage';
import OrdersAutoTreatPage from './autotreatModules/orders/pages/OrdersAutoTreatPage';
import SeedSourcesAutoTreatPage from './autotreatModules/seedSources/pages/SeedSourcesAutoTreatPage';
import LiquidSourcesAutoTreatPage from './autotreatModules/liquidSources/pages/LiquidSourcesAutoTreatPage';
import TransactionApiLogsAutoTreatPage from './autotreatModules/transactionsApiLog/pages/TransactionApiLogsAutoTreatPage';

import NewNotificationPage from './modules/notification/pages/NewNotificationPage';
import NotificationDetailPage from './modules/notification/pages/NotificationDetailPage';
import NotificationListPage from './modules/notification/pages/NotificationListPage';

import LevelSensorsListPage from './autobulkModules/levelSensors/pages/LevelSensorsListPage';
import ProductsAutoBulkcsvImportPage from './autobulkModules/products/pages/ProductsAutoBulkcsvImportPage';
import OnboardingPage from './modules/static/pages/OnboardingPage';
import UnverifiedEmailPage from './modules/static/pages/UnverifiedEmailPage';
import InvalidAccountPage from './modules/static/pages/InvalidAccountPage';
import HomePage from './modules/static/pages/HomePage';
import Sidebar from './modules/navigation/components/Sidebar';
import TopNavigation from './modules/navigation/components/TopNavigation';
import UserTopNavigation from './modules/navigation/components/UserTopNavigation';
import UserProfilePage from './modules/userUsers/pages/UserProfilePage';
import MachineRequestsPage from './modules/machineRequests/pages/MachineRequestsPage';
import MachineRequestRecordsPage from './modules/machineRequests/pages/MachineRequestRecordsPage';
import MachineRequestRecordDetails from './modules/machineRequests/pages/MachineRequestRecordDetails';
import ProductsAutoBulkcsvPartnerImportPage from './autobulkModules/products/pages/ProductsAutoBulkcsvPartnerImportPage';
import CustomersAutoBulkcsvImportPage from './autobulkModules/customersAutoBulk/pages/CustomersAutoBulkcsvImportPage';
import CustomersAutoBulkcsvPartnerImportPage from './autobulkModules/customersAutoBulk/pages/CustomersAutoBulkcsvPartnerImportPage';
import SeedsAutoTreatcsvImportPage from './autotreatModules/seeds/pages/SeedsAutoTreatcsvImportPage';
import SeedsAutoTreatcsvPartnerImportPage from './autotreatModules/seeds/pages/SeedsAutoTreatcsvPartnerImportPage';
import AgconnexSnackbar from './modules/snackBar/components/AgconnexSnackbar';

const useStyles = makeStyles((theme: any) => ({
  box: {
    width: '100%',
  },
  content: {
    width: '100%',
    marginTop: '65px',
  },
}));

const Routes: React.FC = () => {
  const classes = useStyles();

  const { isAuthenticated, isLoading } = useAuth0();
  const [mobileNavigationActive, setMobileNavigationActive] = React.useState<boolean>(false);
  const skipToContentRef = React.useRef<HTMLAnchorElement>(null);
  const { permissionsLoading, currentUserHasAdminPermission } = React.useContext(AuthContext);

  // React.useEffect(() => {
  //   const script = document.createElement('script');
  //   script.src = 'https://chat.dante-ai.com/bubble-embed.js';
  //   script.async = true;
  //   const widgetElement = document.getElementById('support-bot');
  //   if (widgetElement) {
  //     widgetElement.appendChild(script);
  //   }
  //
  //   return () => {
  //     // Clean up the script element on component unmount
  //     if (widgetElement) {
  //       widgetElement.removeChild(script);
  //     }
  //   };
  // }, []);

  const ProtectedRoute = ({ component: Component, path, exact = true }) => (
    <Route
      exact={exact}
      path={path}
      render={(props) =>
        isAuthenticated ? (
          <Frame
            topBar={<UserTopNavigation mobileNavigationActive={mobileNavigationActive} setMobileNavigationActive={setMobileNavigationActive} />}
            navigation={permissionsLoading ? <div /> : <Sidebar />}
            showMobileNavigation={mobileNavigationActive}
            onNavigationDismiss={() => setMobileNavigationActive(false)}
            skipToContentTarget={skipToContentRef}
          >
            <AgconnexSnackbar />
            <div style={{ padding: 30 }}>{permissionsLoading ? <div /> : <Component props={props} component />}</div>
          </Frame>
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );

  const ProtectedAdminRoute = ({ component: Component, path, exact = true }) => (
    <Route
      exact={exact}
      path={path}
      render={(props) =>
        isAuthenticated ? (
          <>
            {!permissionsLoading && !currentUserHasAdminPermission('ksi_support') ? (
              <Redirect to="/login" />
            ) : (
              <Frame
                topBar={<UserTopNavigation mobileNavigationActive={mobileNavigationActive} setMobileNavigationActive={setMobileNavigationActive} />}
                navigation={permissionsLoading ? <div /> : <Sidebar />}
                showMobileNavigation={mobileNavigationActive}
                onNavigationDismiss={() => setMobileNavigationActive(false)}
                skipToContentTarget={skipToContentRef}
              >
                <AgconnexSnackbar />
                <div style={{ padding: 30 }}>{permissionsLoading ? <div /> : <Component props={props} component />}</div>
                <SupportModal />
              </Frame>
            )}
          </>
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );

  const AuthRoute = ({ component: Component, path }) => (
    <Route
      path={path}
      render={(props) => (
        <>
          <TopNavigation />
          <main role="main" className={classes.content}>
            <Component props={props} component />
          </main>
        </>
      )}
    />
  );

  const UnstyledRoute = ({ component: Component, path }) => <Route path={path} render={(props) => <Component props={props} component />} />;

  return isLoading ? (
    <Switch />
  ) : (
    <Switch>
      <AuthRoute path="/login" component={() => <HomePage />} />
      <AuthRoute path="/sign_up" component={() => <OnboardingPage />} />
      <AuthRoute path="/invalid_account" component={() => <InvalidAccountPage />} />
      <AuthRoute path="/unverified_email" component={() => <UnverifiedEmailPage />} />
      {/* Admin Permission Management */}
      <ProtectedAdminRoute exact path="/admin/permission_management" component={() => <PermissionGroupsListPage />} />
      <ProtectedAdminRoute exact path="/admin/permission_management/:permissionGroupId/edit" component={() => <EditPermissionGroupPage />} />
      {/* Admin Execute Machine SQL */}
      <ProtectedAdminRoute exact path="/admin/machine_execute_sql" component={() => <AdminMachineExecuteSql />} />
      {/* Admin Dashboard */}
      <ProtectedAdminRoute exact path="/admin/auditing" component={() => <AuditingDetail />} />
      <ProtectedAdminRoute exact path="/admin/cloud_execute_sql" component={() => <AdminCloudExecuteSql />} />
      <ProtectedAdminRoute exact path="/admin/internal_analytics_autotreat" component={() => <AdminAnalyticsPage />} />
      {/* Admin Machine Management */}
      <ProtectedAdminRoute exact path="/admin/machine_management" component={() => <AdminMachinesListPage />} />
      <ProtectedAdminRoute exact path="/admin/machine_management/new" component={() => <AdminNewMachinePage />} />
      <ProtectedAdminRoute exact path="/admin/machine_certificates" component={() => <AdminMachineCertificatesListPage />} />
      <ProtectedAdminRoute exact path="/admin/machine_management/new/company/:companyId" component={() => <AdminNewMachinePage />} />
      <ProtectedAdminRoute exact path="/admin/machine_management/:machineId" component={() => <AdminMachineDetailPage />} />
      <ProtectedAdminRoute exact path="/admin/machine_management/:machineId/:tab" component={() => <AdminMachineDetailPage />} />
      <ProtectedRoute exact path="/user/machine_management/:machineId" component={() => <UserMachineDetailPage />} />
      <ProtectedRoute exact path="/user/machine_management/:machineId/:tab" component={() => <UserMachineDetailPage />} />
      {/* Admin User Management */}
      <ProtectedAdminRoute exact path="/admin/user_management" component={() => <AdminUsersListPage />} />
      <ProtectedAdminRoute exact path="/admin/user_management/new" component={() => <AdminNewUserPage />} />
      <ProtectedAdminRoute exact path="/admin/user_management/new/company/:companyId" component={() => <AdminNewUserPage />} />
      <ProtectedAdminRoute exact path="/admin/user_management/:userId" component={() => <AdminUserDetailPage />} />
      <ProtectedAdminRoute exact path="/admin/user_management/:userId/:tab" component={() => <AdminUserDetailPage />} />
      {/* Account User Management */}
      <ProtectedRoute exact path="/account_management/users" component={() => <UserUsersListPage />} />
      <ProtectedRoute exact path="/account_management/users/new" component={() => <UserUserNewPage />} />
      <ProtectedRoute exact path="/account_management/users/:userId" component={() => <UserUserDetailPage />} />
      <ProtectedRoute exact path="/profile" component={() => <UserProfilePage />} />
      {/* Machine Request */}
      <ProtectedRoute exact path="/machine_requests" component={() => <MachineRequestsPage />} />
      <ProtectedRoute exact path="/machine_requests/:machineRequestId/machine_request_records" component={() => <MachineRequestRecordsPage />} />
      {/* TODO below should only be for ksi super admins - columns verical with the value to the right */}
      <ProtectedAdminRoute exact path="/machine_requests/:machineRequestId/machine_request_records/:machineRequestRecordId" component={() => <MachineRequestRecordDetails />} />
      {/* Account Machines Management */}
      <ProtectedRoute exact path="/account_management/company_machines" component={() => <UserAllMachinesListPage />} />
      <ProtectedRoute exact path="/account_management/company_machines/:machineSerial" component={() => <UserAllMachinesDetailPage />} />
      <ProtectedRoute exact path="/account_management/company_machines/:machineSerial/:tab" component={() => <UserAllMachinesDetailPage />} />
      <ProtectedRoute exact path="/account_management/company" component={() => <UserCompanyDetailPage />} />
      <ProtectedRoute exact path="/account_management/my_machines" component={() => <UserMachinesListPage />} />
      <ProtectedRoute exact path="/account_management/my_machines/:machineSerial" component={() => <UserMachineDetailPage />} />
      {/* Notification Management */}
      <ProtectedRoute exact path="/account_management/notifications/new" component={() => <NewNotificationPage />} />
      <ProtectedRoute exact path="/account_management/notifications/:notificationId" component={() => <NotificationDetailPage />} />
      <ProtectedRoute exact path="/account_management/notifications/" component={() => <NotificationListPage />} />
      {/* Admin Company Management */}
      <ProtectedAdminRoute exact path="/admin/company_management" component={() => <CompaniesListPage />} />
      <ProtectedAdminRoute exact path="/admin/company_management/new" component={() => <NewCompanyPage />} />
      <ProtectedAdminRoute exact path="/admin/company_management/:companyId" component={() => <CompanyDetailsPage />} />
      <ProtectedAdminRoute exact path="/admin/company_management/:companyId/:tab" component={() => <CompanyDetailsPage />} />
      {/* AutoTreat Analytics */}
      <ProtectedRoute exact path="/autotreat/analytics" component={() => <AutoTreatTransactionSummary />} />
      <ProtectedRoute exact path="/autotreat/analytics/seed_variety_summary" component={() => <SeedVarietySummary />} />
      <ProtectedRoute exact path="/autotreat/analytics/transaction_summary" component={() => <AutoTreatTransactionSummary />} />
      <ProtectedRoute exact path="/autotreat/analytics/treatment_product_summary" component={() => <AutoTreatTreatmentProductSummary />} />
      {/* AutoTreat Machine Management Pages */}

      <ProtectedRoute exact path="/autotreat/machines/:machineSerial/customers_import" component={() => <CustomersAutoTreatcsvImportPage />} />
      <ProtectedRoute exact path="/autotreat/machines/:machineSerial/partners/customers_import" component={() => <CustomersAutoTreatcsvPartnerImportPage />} />
      <ProtectedRoute exact path="/autotreat/machines/customers" component={() => <CustomersAutoTreatPage />} />
      <ProtectedRoute exact path="/autotreat/machines/liquid_sources" component={() => <LiquidSourcesAutoTreatPage />} />
      <ProtectedRoute exact path="/autotreat/machines/orders" component={() => <OrdersAutoTreatPage />} />
      {/* <ProtectedRoute exact path="/autotreat/machines/orders/new" component={() => <OrdersAutoTreatPage />} /> */}
      <ProtectedRoute exact path="/autotreat/machines/recipes" component={() => <RecipesAutoTreatPage />} />
      <ProtectedRoute exact path="/autotreat/machines/seed_sources" component={() => <SeedSourcesAutoTreatPage />} />
      <ProtectedRoute exact path="/autotreat/machines/seeds" component={() => <SeedsAutoTreatPage />} />
      <ProtectedRoute exact path="/autotreat/machines/:machineSerial/seeds_import" component={() => <SeedsAutoTreatcsvImportPage />} />
      <ProtectedRoute exact path="/autotreat/machines/:machineSerial/partners/seeds_import" component={() => <SeedsAutoTreatcsvPartnerImportPage />} />
      <ProtectedRoute exact path="/autotreat/machines/transaction_api_log" component={() => <TransactionApiLogsAutoTreatPage />} />
      <ProtectedRoute exact path="/autotreat/machines/treat_products" component={() => <TreatmentProductsAutoTreatPage />} />
      {/* AutoTreat Misc */}
      <ProtectedRoute exact path="/autotreat/real_time_activity" component={() => <AutoTreatRealTimeActivity />} />
      {/* AutoBulk Analytics */}
      <ProtectedRoute exact path="/autobulk/analytics/product_summary" component={() => <ProductSummary />} />
      <ProtectedRoute exact path="/autobulk/analytics/source_summary" component={() => <SourceSummary />} />
      <ProtectedRoute exact path="/autobulk/analytics/transaction_summary" component={() => <AutoBulkTransactionSummary />} />
      <ProtectedRoute exact path="/autobulk/analytics/transactions/products/details" component={() => <ProductDetailPage />} />
      {/* AutoBulk Machine Management Pages */}
      <ProtectedRoute exact path="/autobulk/machines/:machineSerial/products/:productId" component={() => <ProductsAutoBulkDetailRedirectPage />} />
      <ProtectedRoute exact path="/autobulk/machines/:machineSerial/work_orders/:id" component={() => <WorkOrdersAutoBulkDetailPage />} />
      {/* new autobulk import urls */}
      <ProtectedRoute exact path="/autobulk/machines/:machineSerial/customers_import" component={() => <CustomersAutoBulkcsvImportPage />} />
      <ProtectedRoute exact path="/autobulk/machines/:machineSerial/partners/customers_import" component={() => <CustomersAutoBulkcsvPartnerImportPage />} />
      <ProtectedRoute exact path="/autobulk/machines/customers/" component={() => <CustomersAutoBulkListPage />} />
      <ProtectedRoute exact path="/autobulk/machines/drivers/" component={() => <DriversAutoBulkPage />} />
      <ProtectedRoute exact path="/autobulk/machines/products/" component={() => <ProductsAutoBulkPage />} />
      <ProtectedRoute exact path="/autobulk/machines/rfids/" component={() => <RfidsAutoBulkPage />} />
      <ProtectedRoute exact path="/autobulk/machines/sources" component={() => <SourcesAutoBulkPage />} />
      <ProtectedRoute exact path="/autobulk/machines/transaction_api_log" component={() => <TransactionApiLogsAutoBulkPage />} />
      <ProtectedRoute exact path="/autobulk/machines/transports/" component={() => <TransportsAutoBulkPage />} />
      <ProtectedRoute exact path="/autobulk/machines/work_orders/" component={() => <WorkOrdersAutoBulkPage />} />
      {/* AutoBulk Misc */}
      <ProtectedRoute exact path="/autobulk/machines/:machineSerial/products_import/" component={() => <ProductsAutoBulkcsvImportPage />} />
      <ProtectedRoute exact path="/autobulk/machines/:machineSerial/partners/products_import/" component={() => <ProductsAutoBulkcsvPartnerImportPage />} />
      <ProtectedRoute exact path="/autobulk/level_sensors" component={() => <LevelSensorsListPage />} />
      <ProtectedRoute exact path="/autobulk/real_time_activity" component={() => <RealTimeActivity />} />
      <ProtectedRoute exact path="/" component={() => <MachineDashboardPage />} />
      <ProtectedRoute exact={false} path="/:params" component={() => <Redirect to="/" />} />
    </Switch>
  );
};

export default Routes;
